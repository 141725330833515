import { FC, MouseEventHandler } from "react";
import { IconArrowLeft } from "./icons/arrow-left";
import { IconAdd, IconAddWhite } from "./icons/add";
import { IconInfo, IconInfoWhite } from "./icons/info";

interface Props {
  onBackClick?: MouseEventHandler<HTMLDivElement>;
  onActionClick?: MouseEventHandler<HTMLDivElement>;
  onAddToCartClick?: MouseEventHandler<HTMLDivElement>;
  actionHref?: string;
  actionText: string;
  disclaimer?: boolean;
}

export const PanelFooter: FC<Props> = ({
  onBackClick,
  actionText,
  onActionClick,
  onAddToCartClick,
  actionHref,
  disclaimer,
}) => (
  <div className="footer px-6 py-4 bg-dark  gap-4 w-full flex flex-col">
    {!!disclaimer ? (
      <div className="cursor-pointer hover:opacity-75 justify-center items-center gap-1 flex">
        <div className="h-full">
          <IconInfoWhite className="w-4 h-4 inline mr-2" />
          <div className=" text-white text-xs font-normal inline leading-none">
            Информация на сайте предоставлена в ознакомительных целях и не
            является строительным проектом
          </div>
        </div>
      </div>
    ) : (
      <></>
    )}
    {!!onAddToCartClick ? (
      <div
        onClick={onAddToCartClick}
        className="py-4 cursor-pointer hover:opacity-75 group grow shrink basis-0 h-10 px-4 border border-white justify-center items-center gap-1 flex"
      >
        <div className="pr-4 justify-center items-center gap-2 flex">
          <IconAddWhite className="w-6 h-6 " />
          <div className=" text-white text-base font-normal uppercase">
            Добавить в корзину
          </div>
        </div>
      </div>
    ) : (
      <></>
    )}
    <div className="flex justify-start gap-4 items-center">
      <div
        onClick={onBackClick}
        className="cursor-pointer hover:opacity-75 group grow shrink basis-0 h-10 px-4 border border-white justify-center items-center gap-1 flex"
      >
        <div className="pr-4 justify-center items-center gap-2 flex">
          <IconArrowLeft className="w-4 h-4 transform transition-transform group-hover:-translate-x-0.5" />
          <div className=" text-white text-base font-normal uppercase">
            Назад
          </div>
        </div>
      </div>
      {actionHref ? (
        <a
          href={actionHref}
          target="_blank"
          className="cursor-pointer hover:opacity-75 grow shrink basis-0 h-10 px-4 bg-gradient justify-center items-center flex"
        >
          <div className=" text-white text-base font-normal uppercase">
            {actionText}
          </div>
        </a>
      ) : (
        <div
          onClick={onActionClick}
          className="cursor-pointer hover:opacity-75 grow shrink basis-0 h-10 px-4 bg-gradient justify-center items-center flex"
        >
          <div className=" text-white text-base font-normal uppercase">
            {actionText}
          </div>
        </div>
      )}
    </div>
  </div>
);
