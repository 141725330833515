import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconEnergy = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="#242424"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.25 21.64c-2-.8-3.75-2.25-4.91-4.26a9.89 9.89 0 0 1-1.25-6.25M5.85 4.48A9.936 9.936 0 0 1 12 2.36c2.27 0 4.36.77 6.04 2.05M15.75 21.64c2-.8 3.75-2.25 4.91-4.26a9.89 9.89 0 0 0 1.25-6.25"
    />
    <path
      stroke-miterlimit="10"
      d="M8.752 12.768h1.699v4.32c0 1.008.5 1.211 1.11.456l4.161-5.16c.512-.63.297-1.152-.478-1.152h-1.698v-4.32c0-1.007-.5-1.211-1.11-.456l-4.162 5.16c-.506.636-.291 1.152.478 1.152z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
));
