import { FC } from "react";

export const TextItem: FC<{ title: string; text: string }> = ({
  title,
  text,
}) => {
  return (
    <div className="item py-1 flex-col justify-start items-start gap-1 flex">
      <div className="self-stretch opacity-50 text-dark text-[0.875rem] font-normal leading-[140%]">
        {title}
      </div>
      <div className="self-stretch text-dark text-1rem font-display uppercase tracking-wider">
        {text}
      </div>
    </div>
  );
};
