import { IoSettingsSharp } from "react-icons/io5";
import { useDispatch } from "app/store";
import { Button } from "./button";
import { setScreen } from "../slice";
import { useState } from "react";

export const Login = () => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);
  const handleLogin = () => {
    if (login === "housetech" && password === "housetech") {
      dispatch(setScreen("house"));
    } else {
      setInvalid(true);
    }
  };
  return (
    <div className="fixed w-full h-full flex justify-center items-center top-0 left-0">
      <div className="w-80 px-4 py-3 bg-white rounded shadow-lg">
        <div className="mb-4">
          <div className="text-gray-600 text-sm">Логин</div>
          <input
            className="rounded border-gray-600 border px-2 py-1 mb-2 w-full"
            onChange={e => {
              setInvalid(false);
              setLogin(e.target.value);
            }}
          />
          <div className="text-gray-600 text-sm">Пароль</div>
          <input
            className="rounded border-gray-600 border px-2 py-1 mb-2 w-full"
            type="password"
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
          {invalid && (
            <div className="text-red-400 text-sm">Неверный логин/пароль</div>
          )}
        </div>
        <div
          className="cursor-pointer px-3 py-2 w-20 mx-auto bg-indigo-600 active:bg-indigo-400 rounded text-white text-center"
          onClick={handleLogin}
        >
          Войти
        </div>
      </div>
    </div>
  );
};
