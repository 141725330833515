import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconArrowLeft = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.24978 11L1.78005 8.53026C1.48715 8.23736 1.48715 7.76248 1.78005 7.46958L4.24979 4.99984M1.99973 7.99992L13 7.99992"
      stroke="url(#paint0_linear_311_2753)"
      stroke-width="1.1"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_311_2753"
        x1="13"
        y1="11.0197"
        x2="13"
        y2="4.98011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00C2FF" />
        <stop offset="1" stop-color="#7D48FF" />
      </linearGradient>
    </defs>
  </svg>
));
