import { FC, HTMLAttributes, ReactNode, useState } from "react";
import { IconChevronLeft } from "./icons/chevron-left";
import { Collapse } from "./collapse";
import c from "classnames";

interface Props {
  Icon?: FC<HTMLAttributes<SVGSVGElement>>;
  heading?: string;
  children?: ReactNode;
  range?: boolean;
  className?: string;
  defaultCollapsed?: boolean;
}

export const FancyDropdown: FC<Props> = ({
  Icon,
  heading,
  children,
  range = false,
  className,
  defaultCollapsed = false,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  return (
    <>
      <div
        onClick={() => setCollapsed(prev => !prev)}
        className={c(
          "active-item w-full py-1 justify-between items-center inline-flex cursor-pointer hover:opacity-75",
          {
            "gap-6": range,
            "gap-4": !range,
          },
          className
        )}
      >
        <div
          className={c("flex items-center justify-start", {
            "gap-6": range,
            "gap-4": !range,
          })}
        >
          {Icon && <Icon className="w-6 h-6" />}
          <div className="text-dark text-base font-display uppercase tracking-wider">
            {heading}
          </div>
        </div>
        <IconChevronLeft
          className={c(
            "w-6 h-6 justify-self-end transform transition-transform",
            {
              "rotate-90": !collapsed,
              "-rotate-90": collapsed,
            }
          )}
        />
      </div>
      <Collapse collapsed={collapsed}>
        {children}
        <div className="mb-4"></div>
      </Collapse>
    </>
  );
};
