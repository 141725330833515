import { useLoader } from "@react-three/fiber";
import { mobile } from "app/mobile";
import { useSelector } from "app/store";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FileLoader } from "three";
import { useWindowSize } from "usehooks-ts";

const AVAILABLE_SECTIONS = [
  "1-2-0-0.png",
  "1-2-0-1.png",
  "1-2-0-2.png",
  "1-2-1-0.png",
  "1-2-1-1.png",
  "1-2-1-2.png",
  "1-2-2-0.png",
  "1-2-2-1.png",
  "1-2-2-2.png",
  "1-2-3-0.png",
  "1-2-3-1.png",
  "1-2-3-2.png",
  "1-2-4-0.png",
  "1-2-4-1.png",
  "1-2-4-2.png",
  "1-4-0-0.png",
  "1-4-0-1.png",
  "1-4-0-2.png",
  "1-4-1-0.png",
  "1-4-1-1.png",
  "1-4-1-2.png",
  "1-4-2-0.png",
  "1-4-2-1.png",
  "1-4-2-2.png",
  "1-4-3-0.png",
  "1-4-3-1.png",
  "1-4-3-2.png",
  //"1-5-0-0.png",
  //"1-5-0-1.png",
  "1-6-0-0.png",
  "1-6-0-1.png",
  "1-6-0-2.png",
  "1-6-1-0.png",
  "1-6-1-1.png",
  "1-6-1-2.png",
  "1-6-2-0.png",
  "1-6-2-1.png",
  "1-6-2-2.png",
  "2-2-0-0.png",
  "2-2-0-1.png",
  "2-2-0-2.png",
  "2-2-1-0.png",
  "2-2-1-1.png",
  "2-2-1-2.png",
  "2-2-2-0.png",
  "2-2-2-1.png",
  "2-2-2-2.png",
  "2-2-3-0.png",
  "2-2-3-1.png",
  "2-2-3-2.png",
  "2-2-4-0.png",
  "2-2-4-1.png",
  "2-2-4-2.png",
  "2-4-0-0.png",
  "2-4-0-1.png",
  "2-4-0-2.png",
  "2-4-1-0.png",
  "2-4-1-1.png",
  "2-4-1-2.png",
  "2-4-2-0.png",
  "2-4-2-1.png",
  "2-4-2-2.png",
  "2-4-3-0.png",
  "2-4-3-1.png",
  "2-4-3-2.png",
  //"2-5-0-0.png",
  //"2-5-0-1.png",
  "2-6-0-0.png",
  "2-6-0-1.png",
  "2-6-0-2.png",
  "2-6-1-0.png",
  "2-6-1-1.png",
  "2-6-1-2.png",
  "2-6-2-0.png",
  "2-6-2-1.png",
  "2-6-2-2.png",
  "1-1-0-0.png",
  "1-1-0-1.png",
  "1-1-0-2.png",
  "1-1-1-0.png",
  "1-1-1-1.png",
  "1-1-1-2.png",
  "1-1-10-0.png",
  "1-1-10-1.png",
  "1-1-10-2.png",
  "1-1-2-0.png",
  "1-1-2-1.png",
  "1-1-2-2.png",
  "1-1-3-0.png",
  "1-1-3-1.png",
  "1-1-3-2.png",
  "1-1-4-0.png",
  "1-1-4-1.png",
  "1-1-4-2.png",
  "1-1-5-0.png",
  "1-1-5-1.png",
  "1-1-5-2.png",
  "1-1-6-0.png",
  "1-1-6-1.png",
  "1-1-6-2.png",
  "1-1-7-0.png",
  "1-1-7-1.png",
  "1-1-7-2.png",
  "1-1-8-0.png",
  "1-1-8-1.png",
  "1-1-8-2.png",
  "1-1-9-0.png",
  "1-1-9-1.png",
  "1-1-9-2.png",
].map(x => `./section-pics/${x}`);

const MODELS: { [key: number]: string } = {
  "1": "крыша",
  "2": "межэтажное",
  "3": "стены",
  "4": "полы",
  "5": "цоколь",
  "6": "фундамент",
};

export const HouseSections = () => {
  const house = useSelector(s => s.ui.house);
  const hoverCode = useSelector(s => s.ui.hoverCode);
  const [model, setModel] = useState(0);
  useEffect(() => {
    if (hoverCode > 0) setModel(hoverCode);
  }, [hoverCode]);
  const [variant, tier] = useSelector(s => s.ui.selection.options)[
    MODELS[model]
  ] ?? [0, 0];
  const filename = `./section-pics/${house}-${model}-${variant}-${tier}.png`;
  const screen = useSelector(s => s.ui.screen);
  const show = useSelector(s => s.ui.sectionView);
  const animationRunning = useSelector(s => s.ui.animationRunning);
  const { width, height } = useWindowSize();
  const ratio = height / width;
  const imageRatio = 915 / 1906;
  if (screen === "filter") return null;
  if (screen === "configurator") return null;
  // don't render static images while the camera is moving
  if (animationRunning) return null;
  if (!AVAILABLE_SECTIONS.includes(filename)) return null;
  if (!show) return null;
  if (mobile()) return null;
  return (
    // <img className="w-full h-screen blur-sm object-contain" src={filename} />
    <img
      className={classNames(
        "top-0 left-0 fixed w-full h-screen object-center",
        {
          "object-contain": ratio <= imageRatio,
          "object-cover": ratio > imageRatio,
        }
      )}
      style={{ pointerEvents: "none" }}
      src={filename}
    />
  );
};

// preload images to get rid of loading artifacts
if (!mobile()) {
for (let x of AVAILABLE_SECTIONS) {
  useLoader.preload(FileLoader, x);
}
}