import { useFrame } from "@react-three/fiber";
import { useEventListener } from "app/event-listener";
import { useSelector } from "app/store";
import { useState } from "react";
import { EulerOrder } from "three";

// deprecated. use CameraControls instead
export const HouseCameraAnimator = () => {
  const hc = useSelector(s => s.ui.hoverCode);
  const chc = useSelector(s => s.ui.clickedHoverCode);
  const house = useSelector(s => s.ui.house);
  const [target, setTarget] = useState<string>("");
  useEventListener("click", e => {
    if ((e.target as HTMLElement)?.tagName === "CANVAS" && chc !== -1) {
      // onClick();
      console.log("click", hc, chc, house);
      const key = `${hc} ${chc} ${house}`;
    }
  });

  return null;
};
