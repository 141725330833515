import { POLYETHYLENE_OPACITY } from "config";
import { useMemo } from "react";
import {
  Color,
  Material,
  MeshBasicMaterial,
  MeshStandardMaterial,
} from "three";

export const useTiles = (material: MeshStandardMaterial) =>
  useMemo(() => {
    const m = material.clone();
    // increase texture brightness
    m.emissive = new Color(0x282828);
    m.roughness = 0.5;
    return m;
  }, []);

export const useGround = (material: MeshStandardMaterial) =>
  useMemo(() => {
    const m = new MeshBasicMaterial();
    // copy texture from the original material
    m.map = material.map;
    return m;
  }, []);

export const groundMap = new WeakMap<
  MeshStandardMaterial | MeshBasicMaterial,
  MeshBasicMaterial
>();

export const polyethylene = new MeshBasicMaterial({
  color: 0xd9d8d7,
  opacity: POLYETHYLENE_OPACITY,
  transparent: true,
});
