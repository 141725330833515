import { useSelector } from "app/store";
import { DbEntry } from "features/ui/slice";
import { FC, ReactNode } from "react";

interface Props {
  model: DbEntry;
  variant: number;
  defaultVariant?: boolean;
  children?: ReactNode;
}

export const ConditionalRender: FC<Props> = ({
  model,
  variant,
  defaultVariant,
  children,
}) => {
  const selectionOptions = useSelector(s => s.ui.selection.options);
  const house = useSelector(s => s.ui.house);
  const modelSelection = selectionOptions[model];
  // if there is model selection
  // render children if the variant matches
  let visibilityFlag = false;
  if (modelSelection) {
    if (modelSelection[0] === variant) {
      visibilityFlag = true;
    }
  } else if (defaultVariant) {
    // if there is no model selection
    // render if it is the default variant
    visibilityFlag = true;
  }
  return <group visible={visibilityFlag}>{children}</group>;
};
