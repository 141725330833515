import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

// ФУНДАМЕНТ
export const IconGround = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 9.13831L23.0058 10.5498C23.3314 10.7535 23.3314 11.2465 23.0058 11.4502L12.2572 18.1757C12.0988 18.2748 11.9013 18.2748 11.7428 18.1757L0.994181 11.4502C0.668607 11.2465 0.668605 10.7535 0.994183 10.5498L3.25 9.13831V9.66667C3.25 11.3526 4.74118 12.2833 6 12.2833C7.25882 12.2833 8.75 11.3526 8.75 9.66667V5.69696L9.25 5.38411V13.1333C9.25 14.8193 10.7412 15.75 12 15.75C13.2588 15.75 14.75 14.8193 14.75 13.1333V5.3841L15.25 5.69695V9.66667C15.25 11.3526 16.7412 12.2833 18 12.2833C19.2588 12.2833 20.75 11.3526 20.75 9.66667V9.13831ZM0.98617 15.6368C0.712682 15.4704 0.67672 15.1112 0.878289 14.894L10.8487 21.2126C11.5547 21.6601 12.4483 21.6601 13.1543 21.2126L23.1228 14.8952C23.3232 15.1124 23.2868 15.4707 23.0138 15.6368L12.2652 22.1759C12.1029 22.2747 11.8971 22.2747 11.7348 22.1759L0.98617 15.6368ZM5.75 2C5.75 1.86193 5.86193 1.75 6 1.75C6.13807 1.75 6.25 1.86193 6.25 2V9C6.25 9.13807 6.13807 9.25 6 9.25C5.86193 9.25 5.75 9.13807 5.75 9V2ZM17.75 2C17.75 1.86193 17.8619 1.75 18 1.75C18.1381 1.75 18.25 1.86193 18.25 2V9C18.25 9.13807 18.1381 9.25 18 9.25C17.8619 9.25 17.75 9.13807 17.75 9V2ZM11.75 2C11.75 1.86193 11.8619 1.75 12 1.75C12.1381 1.75 12.25 1.86193 12.25 2V13C12.25 13.1381 12.1381 13.25 12 13.25C11.8619 13.25 11.75 13.1381 11.75 13V2Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
