import { FC, ReactNode, useState } from "react";
import c from "classnames";
import { IconChevronLeft } from "./icons/chevron-left";
import { mobile } from "app/mobile";

interface Props {
  position?: "left" | "right";
  title?: string;
  children?: ReactNode;
}

export const Panel: FC<Props> = ({ position = "right", title, children }) => {
  // compute if pannel should collapse 
  // or be collapsible at all
  const collapsible = mobile();
  let [collapsed, setCollapsed] = useState(collapsible);
  return (
    <div
      className={c("fixed h-screen panel transition-all", {
        "right-0 panel-right": position === "right",
        "left-0 top-0 panel-left": position === "left",
        "not-collapsed": !collapsed
      })}
    >
      <div
        className={c(
          "panel-container absolute h-screen w-screen md:w-[23rem] flex flex-col justify-start items-start transform transition-transform",
          {
            "right-0": position === "right",
            "left-0": position === "left",
          }
        )}
      >
        <div className="filter-widget w-full md:w-[23rem] h-screen flex flex-col">
          {!!title && (
            <div onClick={() => setCollapsed(prev => !prev)} className={c("flex relative justify-between items-center py-4 pl-6 pr-4 bg-dark text-white transform transition-transform ease-in", {"translate-y-[-100%]": collapsed && position === "right"})}>
              <h2 className="font-display uppercase text-1.5rem mr-4">{title}</h2>
              <IconChevronLeft className={c(
            "w-6 h-6 justify-self-end transform transition-transform",
            position === "right" ? {
              "rotate-90": collapsed,
              "-rotate-90": !collapsed,
            } : {
              "-rotate-90": collapsed,
              "rotate-90": !collapsed,
            }, {
              "hidden": !collapsible
            }
          )}/>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
