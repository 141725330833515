import { FC } from "react";
import { NumberCircle } from "./number-circle";

export const NumberItem: FC<{ title: string; number: number }> = ({
  title,
  number,
}) => {
  return (
    <div className="item w-full py-1 justify-between items-center gap-4 flex">
      <div className="text-dark font-display uppercase tracking-wider">
        {title}
      </div>
      <NumberCircle number={number} />
    </div>
  );
};
