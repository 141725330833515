import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconAdd = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>(
  ({ children, ...props }, ref) => (
    <svg
      ref={ref}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.2" cy="12.2" r="7.2" fill="#242424" />
      <path
        d="M11.4868 15.8V8.60004H12.9122V15.8H11.4868ZM7.99951 12.8109V11.5891H16.3995V12.8109H7.99951Z"
        fill="white"
      />
    </svg>
  )
);
export const IconAddWhite = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.2" cy="12.2" r="7.2" fill="white" />
    <path
      d="M11.4868 15.8V8.60004H12.9122V15.8H11.4868ZM7.99951 12.8109V11.5891H16.3995V12.8109H7.99951Z"
      fill="#242424"
    />
  </svg>
));
