/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { HoverGroup } from "./hover-group";
import { HoverMesh } from "./hover-mesh";
import { ConditionalRender } from "./conditional-render";

type GLTFResult = GLTF & {
  nodes: {
    INTER_ER___MEBEL__DLA_VODOSTOKOV: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI: THREE.Mesh;
    OBJ__COKAL: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002: THREE.Mesh;
    PLITA_PEREKR_TIA_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002: THREE.Mesh;
    INTER_ER_SHTORA: THREE.Mesh;
    OBJ__FASAD_2: THREE.Mesh;
    MATERIAL_FASAD_BLACK_BRICK: THREE.Mesh;
    MATERIAL_FASAD_DECOR_BRICK001: THREE.Mesh;
    MATERIAL_FASAD_YELLOW_SIDING: THREE.Mesh;
  };
  materials: {
    ["Material.012"]: THREE.MeshStandardMaterial;
    ["Material.011"]: THREE.MeshStandardMaterial;
    Glass: THREE.MeshStandardMaterial;
    H2_Fasad_white_plaster: THREE.MeshStandardMaterial;
    H1_Fasad_white_plaster: THREE.MeshStandardMaterial;
    H1_Fasad_black_brick: THREE.MeshStandardMaterial;
    H1_Fasad_decor_brick: THREE.MeshStandardMaterial;
    H1_Fasad_yellow_siding: THREE.MeshStandardMaterial;
  };
};

export function House1(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/House1.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <HoverGroup name="Scene">
        <mesh
          name="INTER_ER___MEBEL__DLA_VODOSTOKOV"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_VODOSTOKOV.geometry}
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART.geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          material={materials.Glass}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002
              .geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />

        <HoverMesh
          name="OBJ__COKAL"
          castShadow
          receiveShadow
          code={5}
          geometry={nodes.OBJ__COKAL.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.011"]}
          position={[-4.947, 0.297, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001
              .geometry
          }
          material={materials["Material.012"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002.geometry
          }
          material={materials.Glass}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="PLITA_PEREKR_TIA_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.PLITA_PEREKR_TIA_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.011"]}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA002.geometry
          }
          material={materials.H2_Fasad_white_plaster}
          position={[-4.947, 0.296, 6.587]}
        />
        <mesh
          name="INTER_ER_SHTORA"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER_SHTORA.geometry}
          material={materials["Material.011"]}
          position={[-4.849, 1.562, 5.368]}
          scale={[0.982, 1.001, 1.249]}
        />

        <ConditionalRender model="стены" variant={0} defaultVariant>
          <HoverMesh
            code={3}
            name="OBJ__FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ__FASAD_2.geometry}
            material={materials.H1_Fasad_white_plaster}
            position={[-4.947, 0.296, 6.587]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={2}>
          <HoverMesh
            code={3}
            name="OBJ__FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ__FASAD_2.geometry}
            material={materials.H1_Fasad_black_brick}
            position={[-4.947, 0.296, 6.587]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={1}>
          <HoverMesh
            code={3}
            name="OBJ__FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ__FASAD_2.geometry}
            material={materials.H1_Fasad_decor_brick}
            position={[-4.947, 0.296, 6.587]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={3}>
          <HoverMesh
            code={3}
            name="OBJ__FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ__FASAD_2.geometry}
            material={materials.H1_Fasad_yellow_siding}
            position={[-4.947, 0.296, 6.587]}
          />
        </ConditionalRender>

        <mesh
          name="MATERIAL_FASAD_BLACK_BRICK"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD_BLACK_BRICK.geometry}
          material={materials.H1_Fasad_black_brick}
        />
        <mesh
          name="MATERIAL_FASAD_DECOR_BRICK001"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD_DECOR_BRICK001.geometry}
          material={materials.H1_Fasad_decor_brick}
        />
        <mesh
          name="MATERIAL_FASAD_YELLOW_SIDING"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD_YELLOW_SIDING.geometry}
          material={materials.H1_Fasad_yellow_siding}
        />
      </HoverGroup>
    </group>
  );
}

useGLTF.preload("./meshes/houses/House1.glb");
