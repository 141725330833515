/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { PI } from "app/math";

type GLTFResult = GLTF & {
  nodes: {
    PLITA_PEREKR_TIA_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE: THREE.Mesh;
    INTER_ER___MEBEL__DLA_VODOSTOKOV: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002: THREE.Mesh;
  };
  materials: {
    ["Material.012"]: THREE.MeshStandardMaterial;
    ["Material.015"]: THREE.MeshStandardMaterial;
    ["Material.019"]: THREE.MeshStandardMaterial;
    ["Material.020"]: THREE.MeshStandardMaterial;
    ["Material.021"]: THREE.MeshStandardMaterial;
  };
};

export function House1Map(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/House1.map.glb"
  ) as GLTFResult;
  const glass = useMemo(
    () => new THREE.MeshBasicMaterial({ color: 0xa606060 }),
    []
  );
  return (
    <group {...props} dispose={null}>
      <group
        name="Scene"
        rotation={[0, PI, 0]}
        scale={0.4}
        position={[0, 0.25, 0]}
      >
        <mesh
          name="PLITA_PEREKR_TIA_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.PLITA_PEREKR_TIA_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.015"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_SERAA.geometry
          }
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OTDELKI_VNUTRI.geometry}
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_POVERH__GIPS_STUKATUR_.geometry}
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA_TEMN_J.geometry
          }
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_STEN__OSNOVA.geometry}
          material={materials["Material.012"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DLA_D_MOHODA_VERSOK_SMOOTH_PART.geometry
          }
          material={materials["Material.019"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE.geometry}
          // material={materials["Material.019"]}
          material={glass}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="INTER_ER___MEBEL__DLA_VODOSTOKOV"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_VODOSTOKOV.geometry}
          material={materials["Material.019"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_METALL_NERZAVEUSAA_STAL__FLAT_PAR001
              .geometry
          }
          material={materials["Material.020"]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE002.geometry
          }
          // material={materials["Material.021"]}
          material={glass}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_SOSNA_GORIZONTAL_NAA002
              .geometry
          }
          material={materials["Material.020"]}
          rotation={[0, -1.571, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./meshes/houses/House1.map.glb");
