import { IoSettingsSharp } from "react-icons/io5";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "app/store";
import { Button } from "./button";
import {
  FilterItem,
  setFilterItems,
  setNumberedFilterItems,
  setScreen,
} from "../slice";
import { useState } from "react";
import { CloseButton } from "./close-button";
import { FilterSelector } from "./filter-selector";
import ReactSlider from "react-slider";
import { FilterRange } from "./filter-range";
import { IconHome } from "./icons/home";
import { IconChevronLeft } from "./icons/chevron-left";
import { IconRoof } from "./icons/roof";
import { IconPrice } from "./icons/price";
import { IconEnergy } from "./icons/energy";
import { IconConstruction } from "./icons/construction";
import { IconLifetime } from "./icons/lifetime";
import c from "classnames";
import { IconFilter } from "./icons/filter";
import { FancyDropdown } from "./fancy-dropdown";
import { HouseMapCard } from "./house-map-card";
import {
  CONSTRUCTION_FILTER_ITEMS,
  PRICE_FILTER_ITEMS,
  ROOF_FILTER_ITEMS,
} from "config";
import { mobile } from "app/mobile";

export const Filter = () => {
  const dispatch = useDispatch();
  const filterItems = useSelector(s => s.ui.filterItems);
  const numberedItems = useSelector(s => s.ui.numberedFilterItems);
  const onItemSelect = (item: FilterItem) => (x: number | "all") =>
    dispatch(
      setFilterItems({
        filter: item,
        value: x === "all" ? [0, 1, 2, 3, 4, 5] : filterItems[item].concat(x),
      })
    );
  const onItemDeselect = (item: FilterItem) => (x: number | "all") =>
    dispatch(
      setFilterItems({
        filter: item,
        value: x === "all" ? [] : filterItems[item].filter(y => y !== x),
      })
    );
  // start with filter hidden on mobile
  const [closed, setClosed] = useState(mobile());
  return (
    <div
      className="fixed h-full flex justify-center items-center top-0 left-0 bg-cover bg-center"
      // style={{ backgroundImage: "url(./sky.png)" }}
    >
      <div
        className="filter-button group absolute top-0 left-0 w-14 h-14 flex justify-center items-center bg-dark text-white cursor-pointer"
        onClick={() => setClosed(false)}
      >
        <IconFilter className="w-6 h-6 group-hover:opacity-75 relative top-0.5 right-0.5" />
      </div>
      <div
        className={c(
          "filter-container absolute h-screen left-0 w-screen md:w-[23rem] flex flex-col justify-start items-start transform transition-transform",
          { "-translate-x-full": closed }
        )}
      >
        <div className="filter-widget w-full md:w-[23rem] h-full flex flex-col">
          <div className="flex justify-between items-center py-4 pl-6 pr-4 bg-dark text-white">
            <h2 className="font-display uppercase text-2xl leading-[normal]">
              Фильтр
            </h2>
            <IconChevronLeft
              className="w-6 h-6 mr-2 hover:opacity-75 cursor-pointer"
              onClick={() => setClosed(true)}
            />
          </div>
          <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
            <div className="filter-bg-wrapper px-6">
              <div className="pt-4">
                <FancyDropdown
                  Icon={IconHome}
                  heading="Конструктивное исполнение"
                  className="mb-4"
                >
                  <FilterSelector
                    items={CONSTRUCTION_FILTER_ITEMS}
                    activeItems={filterItems.construction}
                    onItemSelect={onItemSelect("construction")}
                    onItemDeselect={onItemDeselect("construction")}
                  />
                </FancyDropdown>
              </div>
              <FancyDropdown Icon={IconRoof} heading="Крыша" className="mb-4">
                <FilterSelector
                  items={ROOF_FILTER_ITEMS}
                  activeItems={filterItems.roof}
                  onItemSelect={onItemSelect("roof")}
                  onItemDeselect={onItemDeselect("roof")}
                />
              </FancyDropdown>
              <FancyDropdown
                Icon={IconPrice}
                heading="Класс стоимости"
                className="mb-4"
              >
                <FilterSelector
                  items={PRICE_FILTER_ITEMS}
                  activeItems={filterItems.price}
                  onItemSelect={onItemSelect("price")}
                  onItemDeselect={onItemDeselect("price")}
                />
              </FancyDropdown>
              <FancyDropdown
                Icon={IconEnergy}
                heading="Класс энергоэффективности"
                range
                className="mb-2"
              >
                <FilterRange
                  min={1}
                  max={5}
                  defaultValue={numberedItems.energy}
                  onChange={x =>
                    dispatch(
                      setNumberedFilterItems({
                        model: "energy",
                        value: x as [number, number],
                      })
                    )
                  }
                />
              </FancyDropdown>
              <FancyDropdown
                Icon={IconConstruction}
                heading="Срок возведения"
                range
                className="mb-2"
              >
                <FilterRange
                  min={1}
                  max={5}
                  defaultValue={numberedItems.constructionTime}
                  onChange={x =>
                    dispatch(
                      setNumberedFilterItems({
                        model: "constructionTime",
                        value: x as [number, number],
                      })
                    )
                  }
                />
              </FancyDropdown>
              <FancyDropdown
                Icon={IconLifetime}
                heading="Срок эксплуатации"
                range
                className="mb-2"
              >
                <FilterRange
                  min={1}
                  max={5}
                  defaultValue={numberedItems.lifetime}
                  onChange={x =>
                    dispatch(
                      setNumberedFilterItems({
                        model: "lifetime",
                        value: x as [number, number],
                      })
                    )
                  }
                />
              </FancyDropdown>
            </div>
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </div>
  );
};
