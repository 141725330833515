import { IoSettingsSharp } from "react-icons/io5";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "app/store";
import { FilterItem, setFilterItems, setScreen, setVariant } from "../slice";
import { IconList } from "./icons/list";
import { ConfigurationDropdownNumberedItem } from "./configuration-dropdown-numbered-item";
import { Panel } from "./panel";
import { VariantCard } from "./variant-card";
import { PanelFooter } from "./panel-footer";
import { FlexDropdown } from "./flex-dropdown";
import { TextItem } from "./text-item";
import { DefaultViewButton } from "./default-view-button";
import { LegendNumbers } from "./legend-numbers";

export const HouseDetail = () => {
  const dispatch = useDispatch();
  const cm = useSelector(s => s.ui.currentModel);
  const model = useSelector(s => s.ui.models[cm]);
  const cv = useSelector(s => s.ui.currentVariant);
  const ct = useSelector(s => s.ui.currentTier);

  if (!model) return null;

  const variants = model.variants;
  const variant = variants[cv] || variants[0];
  const tiers = variant.tiers || variant.tiers[0];
  const tier = tiers[ct] || tiers[0];

  return (
    <>
      <DefaultViewButton />
      <LegendNumbers />
      <Panel position="left" title={model.name}>
        <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
          <div className="py-6 flex flex-col gap-4">
            {variants.map((v, i) => (
              <VariantCard
                imgSrc={`./${cm}${i}.png`}
                title={v.name}
                price={`От ${v.tiers[0].price}`}
                onClick={() => dispatch(setVariant(i))}
                active={cv === i}
                roofType={v.roofType}
                key={i}
              />
            ))}
          </div>
        </OverlayScrollbarsComponent>
      </Panel>
      <Panel title={variant.name}>
        <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
          <div className="filter-bg-wrapper px-6 min-h-full py-4 flex flex-col gap-4">
            {variant.description ? (
              <div className="text-paragraph text-dark extraction-candidate w-full py-1 flex-col justify-start items-start gap-1 text-[0.875rem] leading-[140%]">
                {variant.description}
              </div>
            ) : (
              <></>
            )}

            <TextItem title="Цена" text={`От ${tiers[0].price}`} />

            {/* <div className="indent w-full h-6"></div> */}

            <FlexDropdown Icon={IconList} heading="Состав">
              <div className="bg-white px-2 py-4 mb-4 flex flex-col">
                {tier.properties
                  .filter(p => p.value !== "-")
                  .map(p => (
                    <ConfigurationDropdownNumberedItem
                      number={p.code}
                      info={p.value !== "-"}
                      title={p.name}
                      caption={p.value}
                      key={p.code}
                    />
                  ))}
              </div>
            </FlexDropdown>
          </div>
        </OverlayScrollbarsComponent>
        <PanelFooter
          onBackClick={() => dispatch(setScreen("house"))}
          onActionClick={() => dispatch(setScreen("configurator"))}
          actionText="Конфигуратор"
        />
      </Panel>
    </>
  );
};
