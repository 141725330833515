import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconFloors = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 13C2.75 12.8619 2.86193 12.75 3 12.75H21C21.1381 12.75 21.25 12.8619 21.25 13V17C21.25 17.1381 21.1381 17.25 21 17.25H3C2.86193 17.25 2.75 17.1381 2.75 17V13ZM2.75 21C2.75 20.8619 2.86193 20.75 3 20.75H4C4.13807 20.75 4.25 20.8619 4.25 21V22C4.25 22.1381 4.13807 22.25 4 22.25H3C2.86193 22.25 2.75 22.1381 2.75 22V21ZM6.75 21C6.75 20.8619 6.86193 20.75 7 20.75H8C8.13807 20.75 8.25 20.8619 8.25 21V22C8.25 22.1381 8.13807 22.25 8 22.25H7C6.86193 22.25 6.75 22.1381 6.75 22V21ZM10.75 21C10.75 20.8619 10.8619 20.75 11 20.75H13C13.1381 20.75 13.25 20.8619 13.25 21V22C13.25 22.1381 13.1381 22.25 13 22.25H11C10.8619 22.25 10.75 22.1381 10.75 22V21ZM15.75 21C15.75 20.8619 15.8619 20.75 16 20.75H17C17.1381 20.75 17.25 20.8619 17.25 21V22C17.25 22.1381 17.1381 22.25 17 22.25H16C15.8619 22.25 15.75 22.1381 15.75 22V21ZM19.75 21C19.75 20.8619 19.8619 20.75 20 20.75H21C21.1381 20.75 21.25 20.8619 21.25 21V22C21.25 22.1381 21.1381 22.25 21 22.25H20C19.8619 22.25 19.75 22.1381 19.75 22V21ZM2.75 3C2.75 2.86193 2.86193 2.75 3 2.75H4C4.13807 2.75 4.25 2.86193 4.25 3V9C4.25 9.13807 4.13807 9.25 4 9.25H3C2.86193 9.25 2.75 9.13807 2.75 9V3ZM19.75 3C19.75 2.86193 19.8619 2.75 20 2.75H21C21.1381 2.75 21.25 2.86193 21.25 3V9C21.25 9.13807 21.1381 9.25 21 9.25H20C19.8619 9.25 19.75 9.13807 19.75 9V3Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
