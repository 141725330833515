import React, { FC } from "react";
import * as THREE from "three";
import { ThreeEvent, Vector3 } from "@react-three/fiber";
import { useDispatch, useSelector } from "app/store";
import { setHoverCode } from "features/ui/slice";
import { Center } from "@react-three/drei";

export const HoverGroup: FC<JSX.IntrinsicElements["group"]> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  return (
    <group
      {...props}
      dispose={null}
      onPointerOver={(e: ThreeEvent<PointerEvent>) => {
        // console.log("hovering in");
      }}
      onPointerOut={() => {
        // console.log("hovering out");
        // dispatch(setHoverCode(-1));
      }}
    >
      {children}
    </group>
  );
};
