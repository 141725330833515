/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { CenterResizeScale } from "../c/center-resize-scale";
import { useEventListener } from "app/event-listener";

type GLTFResult = GLTF & {
  nodes: {
    Wall: THREE.Mesh;
    Base_Skin_0035: THREE.Mesh;
    Base_Skin_0035_1: THREE.Mesh;
    Base_Skin_0035_2: THREE.Mesh;
    Base_Skin_0035_3: THREE.Mesh;
    Cube001: THREE.Mesh;
    Cube001_1: THREE.Mesh;
    Cube001_2: THREE.Mesh;
    Cube001_3: THREE.Mesh;
    Cube001_4: THREE.Mesh;
    Cube001_5: THREE.Mesh;
    Cube001_6: THREE.Mesh;
    Cube001_7: THREE.Mesh;
    Cube001_8: THREE.Mesh;
    Cube001_9: THREE.Mesh;
    Cube001_10: THREE.Mesh;
    Cube001_11: THREE.Mesh;
    Cube001_12: THREE.Mesh;
    Cube001_13: THREE.Mesh;
    Cube001_14: THREE.Mesh;
    Cube001_15: THREE.Mesh;
    Cube001_16: THREE.Mesh;
    Cube001_17: THREE.Mesh;
    Cube001_18: THREE.Mesh;
    Cube001_19: THREE.Mesh;
    Cube001_20: THREE.Mesh;
    Cube001_21: THREE.Mesh;
    Cube001_22: THREE.Mesh;
    Cube001_23: THREE.Mesh;
    Cube001_24: THREE.Mesh;
    Cube001_25: THREE.Mesh;
    ["9"]: THREE.Mesh;
    ["8"]: THREE.Mesh;
    ["7"]: THREE.Mesh;
    ["6"]: THREE.Mesh;
    ["5"]: THREE.Mesh;
    ["4"]: THREE.Mesh;
    ["3"]: THREE.Mesh;
    ["2"]: THREE.Mesh;
    ["16"]: THREE.Mesh;
    ["15"]: THREE.Mesh;
    ["14"]: THREE.Mesh;
    ["13"]: THREE.Mesh;
    ["12"]: THREE.Mesh;
    ["11"]: THREE.Mesh;
    ["10"]: THREE.Mesh;
    ["1"]: THREE.Mesh;
    Cube002: THREE.Mesh;
  };
  materials: {
    BaseWall: THREE.MeshStandardMaterial;
    Skin: THREE.MeshStandardMaterial;
    Shoes: THREE.MeshStandardMaterial;
    Hoodie: THREE.MeshStandardMaterial;
    Pants: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    plane: THREE.MeshStandardMaterial;
    sphere: THREE.MeshStandardMaterial;
    Asphalt: THREE.MeshStandardMaterial;
    ["Material.003"]: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
    ["Material.005"]: THREE.MeshStandardMaterial;
    trunk: THREE.MeshStandardMaterial;
    ["plane.001"]: THREE.MeshStandardMaterial;
    ["sphere.001"]: THREE.MeshStandardMaterial;
    ["Material.013"]: THREE.MeshStandardMaterial;
    plastic: THREE.MeshPhysicalMaterial;
    glass: THREE.MeshStandardMaterial;
    light_red: THREE.MeshStandardMaterial;
    light_orange: THREE.MeshStandardMaterial;
    light_white: THREE.MeshStandardMaterial;
    wheel: THREE.MeshPhysicalMaterial;
    rims: THREE.MeshStandardMaterial;
    grill: THREE.MeshPhysicalMaterial;
    ["Material.014"]: THREE.MeshStandardMaterial;
    ["Material.006"]: THREE.MeshStandardMaterial;
    ["Material.012"]: THREE.MeshStandardMaterial;
    Color_C08: THREE.MeshStandardMaterial;
    Blacktop_Old_02: THREE.MeshStandardMaterial;
    Translucent_Glass_Blue: THREE.MeshStandardMaterial;
    Color_C03: THREE.MeshStandardMaterial;
    ["default.005"]: THREE.MeshStandardMaterial;
    ["default.009"]: THREE.MeshStandardMaterial;
    ["default.010"]: THREE.MeshStandardMaterial;
    ["default.006"]: THREE.MeshStandardMaterial;
    ["default.011"]: THREE.MeshStandardMaterial;
    ["default.015"]: THREE.MeshStandardMaterial;
    ["default.008"]: THREE.MeshStandardMaterial;
    ["default.001"]: THREE.MeshStandardMaterial;
    ["default.012"]: THREE.MeshStandardMaterial;
    ["default.007"]: THREE.MeshStandardMaterial;
    ["default.003"]: THREE.MeshStandardMaterial;
    ["default.014"]: THREE.MeshStandardMaterial;
    ["default.004"]: THREE.MeshStandardMaterial;
    ["default.013"]: THREE.MeshStandardMaterial;
    ["default.002"]: THREE.MeshStandardMaterial;
    ["default"]: THREE.MeshStandardMaterial;
    Grass: THREE.MeshStandardMaterial;
  };
};

export function MapScene(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("./meshes/map.v3.glb") as GLTFResult;

  return (
    <group {...props} dispose={null}>
      <CenterResizeScale
        scale={110}
        position={[10.8, 2.55, 4.5]}
        rotation={[0, 0, 0]}
      >
        <group name="Scene">
          <mesh
            name="Wall"
            castShadow
            receiveShadow
            geometry={nodes.Wall.geometry}
            material={materials.BaseWall}
            position={[6323.029, 0.802, 352.729]}
            scale={0.01}
          />
          <group
            name="Object_7035"
            position={[6343.518, 0.352, 344.199]}
            scale={0.01}
          >
            <mesh
              name="Base_Skin_0035"
              castShadow
              receiveShadow
              geometry={nodes.Base_Skin_0035.geometry}
              material={materials.Skin}
            />
            <mesh
              name="Base_Skin_0035_1"
              castShadow
              receiveShadow
              geometry={nodes.Base_Skin_0035_1.geometry}
              material={materials.Shoes}
            />
            <mesh
              name="Base_Skin_0035_2"
              castShadow
              receiveShadow
              geometry={nodes.Base_Skin_0035_2.geometry}
              material={materials.Hoodie}
            />
            <mesh
              name="Base_Skin_0035_3"
              castShadow
              receiveShadow
              geometry={nodes.Base_Skin_0035_3.geometry}
              material={materials.Pants}
            />
          </group>
          <group name="Cube" position={[6334.646, 2.891, 360.873]} scale={0.01}>
            <mesh
              name="Cube001"
              castShadow
              receiveShadow
              geometry={nodes.Cube001.geometry}
              material={materials["Material.001"]}
            />
            <mesh
              name="Cube001_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_1.geometry}
              material={materials.plane}
            />
            <mesh
              name="Cube001_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_2.geometry}
              material={materials.sphere}
            />
            <mesh
              name="Cube001_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_3.geometry}
              material={materials.Asphalt}
            />
            <mesh
              name="Cube001_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_4.geometry}
              material={materials["Material.003"]}
            />
            <mesh
              name="Cube001_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_5.geometry}
              material={materials["Material.004"]}
            />
            <mesh
              name="Cube001_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_6.geometry}
              material={materials["Material.005"]}
            />
            <mesh
              name="Cube001_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_7.geometry}
              material={materials.trunk}
            />
            <mesh
              name="Cube001_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_8.geometry}
              material={materials["plane.001"]}
            />
            <mesh
              name="Cube001_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_9.geometry}
              material={materials["sphere.001"]}
            />
            <mesh
              name="Cube001_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_10.geometry}
              material={materials["Material.013"]}
            />
            <mesh
              name="Cube001_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_11.geometry}
              material={materials.plastic}
            />
            <mesh
              name="Cube001_12"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_12.geometry}
              material={materials.glass}
            />
            <mesh
              name="Cube001_13"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_13.geometry}
              material={materials.light_red}
            />
            <mesh
              name="Cube001_14"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_14.geometry}
              material={materials.light_orange}
            />
            <mesh
              name="Cube001_15"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_15.geometry}
              material={materials.light_white}
            />
            <mesh
              name="Cube001_16"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_16.geometry}
              material={materials.wheel}
            />
            <mesh
              name="Cube001_17"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_17.geometry}
              material={materials.rims}
            />
            <mesh
              name="Cube001_18"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_18.geometry}
              material={materials.grill}
            />
            <mesh
              name="Cube001_19"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_19.geometry}
              material={materials["Material.014"]}
            />
            <mesh
              name="Cube001_20"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_20.geometry}
              material={materials["Material.006"]}
            />
            <mesh
              name="Cube001_21"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_21.geometry}
              material={materials["Material.012"]}
            />
            <mesh
              name="Cube001_22"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_22.geometry}
              material={materials.Color_C08}
            />
            <mesh
              name="Cube001_23"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_23.geometry}
              material={materials.Blacktop_Old_02}
            />
            <mesh
              name="Cube001_24"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_24.geometry}
              material={materials.Translucent_Glass_Blue}
            />
            <mesh
              name="Cube001_25"
              castShadow
              receiveShadow
              geometry={nodes.Cube001_25.geometry}
              material={materials.Color_C03}
            />
          </group>
          <group visible={false}>
            <mesh
              name="9"
              castShadow
              receiveShadow
              geometry={nodes["9"].geometry}
              material={materials["default.005"]}
              position={[6323.854, 1.57, 333.414]}
              rotation={[0, 0.153, 0]}
              scale={0.002}
            />
            <mesh
              name="8"
              castShadow
              receiveShadow
              geometry={nodes["8"].geometry}
              material={materials["default.009"]}
              position={[6310.042, 1.57, 368.586]}
              rotation={[0, 0.215, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="7"
              castShadow
              receiveShadow
              geometry={nodes["7"].geometry}
              material={materials["default.010"]}
              position={[6312.119, 1.57, 345.425]}
              rotation={[0, 0.142, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="6"
              castShadow
              receiveShadow
              geometry={nodes["6"].geometry}
              material={materials["default.006"]}
              position={[6307.779, 1.57, 336.284]}
              rotation={[0, 0.153, 0]}
              scale={0.002}
            />
            <mesh
              name="5"
              castShadow
              receiveShadow
              geometry={nodes["5"].geometry}
              material={materials["default.011"]}
              position={[6295.684, 1.57, 339.042]}
              rotation={[0, 0.142, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="4"
              castShadow
              receiveShadow
              geometry={nodes["4"].geometry}
              material={materials["default.015"]}
              position={[6296.734, 1.57, 354.125]}
              rotation={[0, 0.117, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="3"
              castShadow
              receiveShadow
              geometry={nodes["3"].geometry}
              material={materials["default.008"]}
              position={[6296.448, 1.57, 361.773]}
              rotation={[0, 0.117, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="2"
              castShadow
              receiveShadow
              geometry={nodes["2"].geometry}
              material={materials["default.001"]}
              position={[6292.585, 1.57, 369.355]}
              rotation={[0, 0.156, 0]}
              scale={0.002}
            />
            <mesh
              name="16"
              castShadow
              receiveShadow
              geometry={nodes["16"].geometry}
              material={materials["default.012"]}
              position={[6327.361, 1.57, 342.512]}
              rotation={[0, 0.008, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="15"
              castShadow
              receiveShadow
              geometry={nodes["15"].geometry}
              material={materials["default.007"]}
              position={[6366.766, 1.57, 337.766]}
              rotation={[0, -0.003, 0]}
              scale={0.002}
            />
            <mesh
              name="14"
              castShadow
              receiveShadow
              geometry={nodes["14"].geometry}
              material={materials["default.003"]}
              position={[6350.926, 1.57, 356.727]}
              rotation={[0, 0.183, 0]}
              scale={0.002}
            />
            <mesh
              name="13"
              castShadow
              receiveShadow
              geometry={nodes["13"].geometry}
              material={materials["default.014"]}
              position={[6347.735, 1.57, 347.581]}
              rotation={[0, 0.182, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="12"
              castShadow
              receiveShadow
              geometry={nodes["12"].geometry}
              material={materials["default.004"]}
              position={[6343.095, 1.57, 338.344]}
              rotation={[0, -1.425, 0]}
              scale={0.002}
            />
            <mesh
              name="11"
              castShadow
              receiveShadow
              geometry={nodes["11"].geometry}
              material={materials["default.013"]}
              position={[6330.152, 1.57, 362.119]}
              rotation={[0, 0.182, 0]}
              scale={[0.001, 0.002, 0.002]}
            />
            <mesh
              name="10"
              castShadow
              receiveShadow
              geometry={nodes["10"].geometry}
              material={materials["default.002"]}
              position={[6329.156, 1.57, 352.874]}
              rotation={[0, 0.092, 0]}
              scale={0.002}
            />
            <mesh
              name="1"
              castShadow
              receiveShadow
              geometry={nodes["1"].geometry}
              material={materials["default"]}
              position={[6294.688, 1.57, 382.309]}
              scale={0.002}
            />
          </group>
          <mesh
            name="Cube002"
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.Grass}
            position={[6326.291, 0, 352.53]}
            scale={0.01}
          />
        </group>
      </CenterResizeScale>
    </group>
  );
}

useGLTF.preload("./meshes/map.v3.glb");
