import React, { FC, useMemo } from "react";
import * as THREE from "three";
import { Euler, ThreeEvent, Vector3 } from "@react-three/fiber";
import { useDispatch, useSelector } from "app/store";
import { setHoverCode, setSectionView } from "features/ui/slice";
import { useCursor } from "@react-three/drei";
import { groundMap } from "app/materials";
import { MeshBasicMaterial, MeshStandardMaterial } from "three";

export interface Props {
  geometry: THREE.BufferGeometry;
  material: MeshBasicMaterial | MeshStandardMaterial;
  onPointerOver?: (e: ThreeEvent<PointerEvent>) => void;
  onPointerOut?: (e: ThreeEvent<PointerEvent>) => void;
  position?: Vector3;
  rotation?: Euler;
  scale?: Vector3;
  code?: number;
  name?: string;
  castShadow?: boolean;
  receiveShadow?: boolean;
  children?: React.ReactNode;
}

export const HoverMesh: FC<Props> = ({
  geometry,
  material,
  position,
  rotation,
  scale,
  receiveShadow = true,
  code,
  name,
  children,
}) => {
  const dispatch = useDispatch();
  const hc = useSelector(s => s.ui.hoverCode);

  const _code: number | undefined = useMemo(() => {
    let c: number | undefined;
    if (typeof code === "number") return code;
    try {
      c = parseInt(name || "");
    } catch (e) {
      c = code;
    }
    return c;
  }, []);

  const hover = hc === _code;
  useCursor(hover);
  return (
    <>
      <mesh
        castShadow
        receiveShadow={receiveShadow}
        geometry={geometry}
        material={material}
        position={position}
        rotation={rotation}
        scale={scale}
        onPointerOver={e => {
          e.stopPropagation();
          // console.log("hover", _code);
          if (_code) {
            dispatch(setHoverCode(_code));
            dispatch(setSectionView(false));
          }
        }}
        onPointerOut={() => {
          // console.log("hover out");
          dispatch(setHoverCode(-1));
        }}
      >
        {children}
      </mesh>
      {hover && (
        <group position={position} rotation={rotation} scale={scale}>
          <mesh geometry={geometry}>
            <meshStandardMaterial
              color="#16a34a"
              attach="material"
              opacity={0.33}
              side={THREE.DoubleSide}
              transparent
            />
          </mesh>
        </group>
      )}
    </>
  );
};
