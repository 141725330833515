import { useTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import {
  EffectComposer,
  SSR,
  Bloom,
  LUT,
  DepthOfField,
  Noise,
  Vignette,
  SSAO,
} from "@react-three/postprocessing";
import { ComponentProps, FC } from "react";
import { BlendFunction } from "postprocessing";

type Props = ComponentProps<typeof SSR> & {
  enabled?: boolean;
  // depth of field
  dof?: boolean;
};

export const Effects: FC<Props> = ({
  enabled = true,
  dof = true,
  ...props
}) => {
  /* const { enabled, ...props } = useControls({
    enabled: true,
    temporalResolve: true,
    STRETCH_MISSED_RAYS: true,
    USE_MRT: true,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: true,
    ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    temporalResolveMix: { value: 0.9, min: 0, max: 1 },
    temporalResolveCorrectionMix: { value: 0.4, min: 0, max: 1 },
    maxSamples: { value: 0, min: 0, max: 1 },
    resolutionScale: { value: 1, min: 0, max: 1 },
    blurMix: { value: 0.2, min: 0, max: 1 },
    blurExponent: { value: 10, min: 0, max: 20 },
    blurKernelSize: { value: 1, min: 0, max: 10 },
    rayStep: { value: 0.5, min: 0, max: 1 },
    intensity: { value: 1, min: 0, max: 5 },
    maxRoughness: { value: 1, min: 0, max: 1 },
    jitter: { value: 0.3, min: 0, max: 5 },
    jitterSpread: { value: 0.25, min: 0, max: 1 },
    jitterRough: { value: 0.1, min: 0, max: 1 },
    roughnessFadeOut: { value: 1, min: 0, max: 1 },
    rayFadeOut: { value: 0, min: 0, max: 1 },
    MAX_STEPS: { value: 20, min: 0, max: 20 },
    NUM_BINARY_SEARCH_STEPS: { value: 6, min: 0, max: 10 },
    maxDepthDifference: { value: 10, min: 0, max: 10 },
    maxDepth: { value: 1, min: 0, max: 1 },
    thickness: { value: 10, min: 0, max: 10 },
    ior: { value: 1.45, min: 0, max: 2 },
  });*/

  let ssr = {
    enabled: true,
    temporalResolve: true,
    STRETCH_MISSED_RAYS: true,
    USE_MRT: true,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: true,
    ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    temporalResolveMix: { value: 0.9, min: 0, max: 1 },
    temporalResolveCorrectionMix: { value: 0.4, min: 0, max: 1 },
    maxSamples: { value: 0, min: 0, max: 1 },
    resolutionScale: { value: 0.75, min: 0, max: 1 },
    blurMix: { value: 0.2, min: 0, max: 1 },
    blurExponent: { value: 10, min: 0, max: 20 },
    blurKernelSize: { value: 1, min: 0, max: 10 },
    rayStep: { value: 0.5, min: 0, max: 1 },
    intensity: { value: 1, min: 0, max: 5 },
    maxRoughness: { value: 1, min: 0, max: 1 },
    jitter: { value: 0.3, min: 0, max: 5 },
    jitterSpread: { value: 0.25, min: 0, max: 1 },
    jitterRough: { value: 0.1, min: 0, max: 1 },
    roughnessFadeOut: { value: 1, min: 0, max: 1 },
    rayFadeOut: { value: 0, min: 0, max: 1 },
    MAX_STEPS: { value: 20, min: 0, max: 20 },
    NUM_BINARY_SEARCH_STEPS: { value: 6, min: 0, max: 10 },
    maxDepthDifference: { value: 10, min: 0, max: 10 },
    maxDepth: { value: 1, min: 0, max: 1 },
    thickness: { value: 10, min: 0, max: 10 },
    ior: { value: 1.45, min: 0, max: 2 },
  };

  for (let i in ssr) {
    // @ts-ignore
    if (ssr[i].value) ssr[i] = ssr[i].value;
  }

  const [texture] = useTexture(["/bk.png"]);
  return enabled ? (
    <EffectComposer disableNormalPass>
      {/*// @ts-ignore*/}
      {/* <SSR {...ssr} /> */}
      {/*<Bloom
        luminanceThreshold={0.5}
        mipmapBlur
        luminanceSmoothing={0}
        intensity={0}
  />*/}
      {/* <LUT lut={texture} /> */}
      {dof ? (
        <DepthOfField
          focusDistance={0}
          focalLength={0.01}
          bokehScale={2}
          height={480}
        />
      ) : (
        <DepthOfField
          focusDistance={0}
          focalLength={0.05}
          bokehScale={2}
          height={480}
        />
      )}
      <Bloom luminanceThreshold={0.9} luminanceSmoothing={0.2} height={100} />
      <Noise opacity={0.02} />
      <Vignette eskil={false} offset={0.05} darkness={0.95} />
      {/* <SSAO
        blendFunction={BlendFunction.MULTIPLY} // blend mode
        samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
        rings={4} // amount of rings in the occlusion sampling pattern
        distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
        distanceFalloff={0.0} // distance falloff. min: 0, max: 1
        rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
        rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
        luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
        radius={20} // occlusion sampling radius
        // scale={0.5} // scale of the ambient occlusion
        bias={0.5} // occlusion bias
        worldDistanceThreshold={0}
        worldDistanceFalloff={0}
        worldProximityThreshold={0}
        worldProximityFalloff={0}
      /> */}
    </EffectComposer>
  ) : null;
};
