/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Box251: THREE.Mesh;
    Box251001: THREE.Mesh;
    Cube: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144001: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144002: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144004: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144005: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144008: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144009: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144010: THREE.Mesh;
    MT_PM_V51_Rhamnus_alaternus_01_144011: THREE.Mesh;
    Object001: THREE.Mesh;
    Object011001: THREE.Mesh;
    Object011002: THREE.Mesh;
    Object011004: THREE.Mesh;
    Object011005: THREE.Mesh;
    Object011008: THREE.Mesh;
    Object011009: THREE.Mesh;
    Object011010: THREE.Mesh;
    Object011011: THREE.Mesh;
    Object012: THREE.Mesh;
    Object012001: THREE.Mesh;
    Object012002: THREE.Mesh;
    Object012003: THREE.Mesh;
    Object012004: THREE.Mesh;
    Object012005: THREE.Mesh;
    Object012006: THREE.Mesh;
    Object012007: THREE.Mesh;
    Object012008: THREE.Mesh;
    Object012009: THREE.Mesh;
    Object012010: THREE.Mesh;
    Object012011: THREE.Mesh;
    Object012012: THREE.Mesh;
    Object012013: THREE.Mesh;
    Object012014: THREE.Mesh;
    Object012015: THREE.Mesh;
    Object012016: THREE.Mesh;
    Object012017: THREE.Mesh;
    Object012018: THREE.Mesh;
    Object012019: THREE.Mesh;
    Object012020: THREE.Mesh;
    Object012021: THREE.Mesh;
    Object012022: THREE.Mesh;
    Object012023: THREE.Mesh;
    Object012024: THREE.Mesh;
    Object012026: THREE.Mesh;
    Object012027: THREE.Mesh;
    Object012028: THREE.Mesh;
    Object012029: THREE.Mesh;
    Object012030: THREE.Mesh;
    Object012031: THREE.Mesh;
    Object012032: THREE.Mesh;
    Object012033: THREE.Mesh;
    Object012034: THREE.Mesh;
    Object012035: THREE.Mesh;
    Object012036: THREE.Mesh;
    Object012037: THREE.Mesh;
    Object013: THREE.Mesh;
    Object013001: THREE.Mesh;
    Object013002: THREE.Mesh;
    Object013003: THREE.Mesh;
    Object013004: THREE.Mesh;
    Object013005: THREE.Mesh;
    Object013006: THREE.Mesh;
    Object013007: THREE.Mesh;
    Object013008: THREE.Mesh;
    Object013009: THREE.Mesh;
    Object013010: THREE.Mesh;
    Object013011: THREE.Mesh;
    Object013012: THREE.Mesh;
    Object013013: THREE.Mesh;
    Object013014: THREE.Mesh;
    Object013015: THREE.Mesh;
    Object013016: THREE.Mesh;
    Object013017: THREE.Mesh;
    Object013018: THREE.Mesh;
    Object013019: THREE.Mesh;
    Object013020: THREE.Mesh;
    Object013021: THREE.Mesh;
    Object013022: THREE.Mesh;
    Object013023: THREE.Mesh;
    Object013024: THREE.Mesh;
    Object013025: THREE.Mesh;
    Object013026: THREE.Mesh;
    Object013027: THREE.Mesh;
    Object013028: THREE.Mesh;
    Object013029: THREE.Mesh;
    Object013030: THREE.Mesh;
    Object013031: THREE.Mesh;
    Object013032: THREE.Mesh;
    Object013037: THREE.Mesh;
    Object013039: THREE.Mesh;
    Object013040: THREE.Mesh;
    Object013041: THREE.Mesh;
    Object013042: THREE.Mesh;
    Object013043: THREE.Mesh;
    Object013044: THREE.Mesh;
    Object014: THREE.Mesh;
    Object014001: THREE.Mesh;
    Object014003: THREE.Mesh;
    Object014004: THREE.Mesh;
    Object014007: THREE.Mesh;
    Object014008: THREE.Mesh;
    Object014009: THREE.Mesh;
    Object014011: THREE.Mesh;
    Object014012: THREE.Mesh;
    Object014013: THREE.Mesh;
    Object015: THREE.Mesh;
    Object015001: THREE.Mesh;
    Object015002: THREE.Mesh;
    Object015003: THREE.Mesh;
    Object015004: THREE.Mesh;
    Object015005: THREE.Mesh;
    Object015006: THREE.Mesh;
    Object015007: THREE.Mesh;
    Object015008: THREE.Mesh;
    Object015009: THREE.Mesh;
    Object015010: THREE.Mesh;
    Object015011: THREE.Mesh;
    Object015012: THREE.Mesh;
    Object015013: THREE.Mesh;
    Object015014: THREE.Mesh;
    Object015015: THREE.Mesh;
    Object015016: THREE.Mesh;
    Object015017: THREE.Mesh;
    Object015018: THREE.Mesh;
    Object015019: THREE.Mesh;
    Object015020: THREE.Mesh;
    Object015021: THREE.Mesh;
    Object015022: THREE.Mesh;
    Object015023: THREE.Mesh;
    Object015024: THREE.Mesh;
    Object015026: THREE.Mesh;
    Object015027: THREE.Mesh;
    Object015028: THREE.Mesh;
    Object015029: THREE.Mesh;
    Object015030: THREE.Mesh;
    Object015031: THREE.Mesh;
    Object015032: THREE.Mesh;
    Object015033: THREE.Mesh;
    Object015034: THREE.Mesh;
    Object015035: THREE.Mesh;
    Object015036: THREE.Mesh;
    Object016: THREE.Mesh;
    Object016001: THREE.Mesh;
    Object016002: THREE.Mesh;
    Object016003: THREE.Mesh;
    Object016004: THREE.Mesh;
    Object016005: THREE.Mesh;
    Object016006: THREE.Mesh;
    Object016007: THREE.Mesh;
    Object016008: THREE.Mesh;
    Object016009: THREE.Mesh;
    Object016010: THREE.Mesh;
    Object016011: THREE.Mesh;
    Object016012: THREE.Mesh;
    Object016013: THREE.Mesh;
    Object016014: THREE.Mesh;
    Object016015: THREE.Mesh;
    Object016016: THREE.Mesh;
    Object016017: THREE.Mesh;
    Object016018: THREE.Mesh;
    Object016019: THREE.Mesh;
    Object016020: THREE.Mesh;
    Object016021: THREE.Mesh;
    Object016022: THREE.Mesh;
    Object016023: THREE.Mesh;
    Object016024: THREE.Mesh;
    Object016025: THREE.Mesh;
    Object016026: THREE.Mesh;
    Object016027: THREE.Mesh;
    Object016028: THREE.Mesh;
    Object016029: THREE.Mesh;
    Object016030: THREE.Mesh;
    Object016031: THREE.Mesh;
    Object016032: THREE.Mesh;
    Object016037: THREE.Mesh;
    Object016039: THREE.Mesh;
    Object016040: THREE.Mesh;
    Object016041: THREE.Mesh;
    Object016042: THREE.Mesh;
    Object016043: THREE.Mesh;
    Object016044: THREE.Mesh;
    Object017: THREE.Mesh;
    Object017001: THREE.Mesh;
    Object017003: THREE.Mesh;
    Object017004: THREE.Mesh;
    Object017007: THREE.Mesh;
    Object017008: THREE.Mesh;
    Object017009: THREE.Mesh;
    Object017011: THREE.Mesh;
    Object017012: THREE.Mesh;
    Object017013: THREE.Mesh;
    Plane001: THREE.Mesh;
  };
  materials: {
    ["Material.003"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
  };
};

export function Porch(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/Building_area.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <mesh
        name="Box251"
        castShadow
        receiveShadow
        geometry={nodes.Box251.geometry}
        material={materials["Material.003"]}
        position={[28.786, 0.264, -7.823]}
      />
      <mesh
        name="Box251001"
        castShadow
        receiveShadow
        geometry={nodes.Box251001.geometry}
        material={materials["Material.003"]}
        position={[28.786, 0.264, -7.823]}
      />
      <mesh
        name="Cube"
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["Material.001"]}
        position={[-8.065, 0.13, 12.319]}
        scale={[0.04, 0.25, 0.04]}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144001"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144001.geometry}
        material={materials["Material.004"]}
        position={[20.647, -0.039, -7.018]}
        scale={0.68}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144002"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144002.geometry}
        material={materials["Material.004"]}
        position={[24.349, -0.039, 9.828]}
        rotation={[0, 1.221, 0]}
        scale={0.68}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144004"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144004.geometry}
        material={materials["Material.004"]}
        position={[-31.536, -0.039, 25.068]}
        rotation={[0, 0.142, 0]}
        scale={0.746}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144005"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144005.geometry}
        material={materials["Material.004"]}
        position={[-34.58, -0.039, 6.887]}
        rotation={[0, 0.142, 0]}
        scale={0.746}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144008"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144008.geometry}
        material={materials["Material.004"]}
        position={[-38.74, -0.106, -3.101]}
        rotation={[0, 0.524, 0]}
        scale={1.313}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144009"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144009.geometry}
        material={materials["Material.004"]}
        position={[-31.032, -0.039, -18.19]}
        rotation={[0, 0.524, 0]}
        scale={0.746}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144010"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144010.geometry}
        material={materials["Material.004"]}
        position={[-20.458, -0.039, -19.38]}
        rotation={[0, 0.524, 0]}
        scale={0.885}
      />
      <mesh
        name="MT_PM_V51_Rhamnus_alaternus_01_144011"
        castShadow
        receiveShadow
        geometry={nodes.MT_PM_V51_Rhamnus_alaternus_01_144011.geometry}
        material={materials["Material.004"]}
        position={[1.541, -0.039, -22.275]}
        rotation={[0, 0.524, 0]}
        scale={0.885}
      />
      <mesh
        name="Object001"
        castShadow
        receiveShadow
        geometry={nodes.Object001.geometry}
        material={materials["Material.003"]}
        position={[-1.18, 0.054, 2.718]}
        scale={[1.189, 1, 1]}
      />
      <mesh
        name="Object011001"
        castShadow
        receiveShadow
        geometry={nodes.Object011001.geometry}
        material={materials["Material.004"]}
        position={[23.458, -0.039, -7.018]}
        scale={0.68}
      />
      <mesh
        name="Object011002"
        castShadow
        receiveShadow
        geometry={nodes.Object011002.geometry}
        material={materials["Material.004"]}
        position={[25.313, -0.039, 7.187]}
        rotation={[0, 1.221, 0]}
        scale={0.68}
      />
      <mesh
        name="Object011004"
        castShadow
        receiveShadow
        geometry={nodes.Object011004.geometry}
        material={materials["Material.004"]}
        position={[-28.482, -0.039, 24.632]}
        rotation={[0, 0.142, 0]}
        scale={0.746}
      />
      <mesh
        name="Object011005"
        castShadow
        receiveShadow
        geometry={nodes.Object011005.geometry}
        material={materials["Material.004"]}
        position={[-31.527, -0.039, 6.451]}
        rotation={[0, 0.142, 0]}
        scale={0.746}
      />
      <mesh
        name="Object011008"
        castShadow
        receiveShadow
        geometry={nodes.Object011008.geometry}
        material={materials["Material.004"]}
        position={[-34.038, -0.106, -5.817]}
        rotation={[0, 0.524, 0]}
        scale={1.313}
      />
      <mesh
        name="Object011009"
        castShadow
        receiveShadow
        geometry={nodes.Object011009.geometry}
        material={materials["Material.004"]}
        position={[-28.362, -0.039, -19.733]}
        rotation={[0, 0.524, 0]}
        scale={0.746}
      />
      <mesh
        name="Object011010"
        castShadow
        receiveShadow
        geometry={nodes.Object011010.geometry}
        material={materials["Material.004"]}
        position={[-17.289, -0.039, -21.211]}
        rotation={[0, 0.524, 0]}
        scale={0.885}
      />
      <mesh
        name="Object011011"
        castShadow
        receiveShadow
        geometry={nodes.Object011011.geometry}
        material={materials["Material.004"]}
        position={[4.71, -0.039, -24.105]}
        rotation={[0, 0.524, 0]}
        scale={0.885}
      />
      <mesh
        name="Object012"
        castShadow
        receiveShadow
        geometry={nodes.Object012.geometry}
        material={materials["Material.004"]}
        position={[14.669, -0.039, -19.082]}
        scale={1.131}
      />
      <mesh
        name="Object012001"
        castShadow
        receiveShadow
        geometry={nodes.Object012001.geometry}
        material={materials["Material.004"]}
        position={[1.732, -0.039, -13.638]}
        scale={1.274}
      />
      <mesh
        name="Object012002"
        castShadow
        receiveShadow
        geometry={nodes.Object012002.geometry}
        material={materials["Material.004"]}
        position={[23.399, -0.039, -20.199]}
        scale={1.131}
      />
      <mesh
        name="Object012003"
        castShadow
        receiveShadow
        geometry={nodes.Object012003.geometry}
        material={materials["Material.004"]}
        position={[9.655, -0.039, -24.76]}
        scale={1.131}
      />
      <mesh
        name="Object012004"
        castShadow
        receiveShadow
        geometry={nodes.Object012004.geometry}
        material={materials["Material.004"]}
        position={[-6.746, -0.039, -25.485]}
        scale={1.131}
      />
      <mesh
        name="Object012005"
        castShadow
        receiveShadow
        geometry={nodes.Object012005.geometry}
        material={materials["Material.004"]}
        position={[-13.161, -0.039, -22.876]}
        scale={1.131}
      />
      <mesh
        name="Object012006"
        castShadow
        receiveShadow
        geometry={nodes.Object012006.geometry}
        material={materials["Material.004"]}
        position={[-24.18, -0.039, -22.622]}
        scale={1.131}
      />
      <mesh
        name="Object012007"
        castShadow
        receiveShadow
        geometry={nodes.Object012007.geometry}
        material={materials["Material.004"]}
        position={[-30.161, -0.039, -9.465]}
        scale={1.131}
      />
      <mesh
        name="Object012008"
        castShadow
        receiveShadow
        geometry={nodes.Object012008.geometry}
        material={materials["Material.004"]}
        position={[-14.792, -0.039, -8.124]}
        scale={1.131}
      />
      <mesh
        name="Object012009"
        castShadow
        receiveShadow
        geometry={nodes.Object012009.geometry}
        material={materials["Material.004"]}
        position={[-17.624, -0.039, -1.624]}
        rotation={[0, 0.617, 0]}
        scale={1.131}
      />
      <mesh
        name="Object012010"
        castShadow
        receiveShadow
        geometry={nodes.Object012010.geometry}
        material={materials["Material.004"]}
        position={[-28.738, -0.039, 7.71]}
        rotation={[0, 1.284, 0]}
        scale={1.131}
      />
      <mesh
        name="Object012011"
        castShadow
        receiveShadow
        geometry={nodes.Object012011.geometry}
        material={materials["Material.004"]}
        position={[-19.042, -0.039, 2.45]}
        rotation={[0, 1.284, 0]}
      />
      <mesh
        name="Object012012"
        castShadow
        receiveShadow
        geometry={nodes.Object012012.geometry}
        material={materials["Material.004"]}
        position={[-14.195, -0.039, 12.754]}
        rotation={[0, 1.284, 0]}
        scale={1.04}
      />
      <mesh
        name="Object012013"
        castShadow
        receiveShadow
        geometry={nodes.Object012013.geometry}
        material={materials["Material.004"]}
        position={[-28.06, -0.039, 24.717]}
        rotation={[0, 0.862, 0]}
        scale={1.115}
      />
      <mesh
        name="Object012014"
        castShadow
        receiveShadow
        geometry={nodes.Object012014.geometry}
        material={materials["Material.004"]}
        position={[-5.406, -0.039, 17.214]}
        rotation={[0, 0.862, 0]}
        scale={1.115}
      />
      <mesh
        name="Object012015"
        castShadow
        receiveShadow
        geometry={nodes.Object012015.geometry}
        material={materials["Material.004"]}
        position={[20.847, -0.039, 20.384]}
        rotation={[0, 0.862, 0]}
        scale={1.263}
      />
      <mesh
        name="Object012016"
        castShadow
        receiveShadow
        geometry={nodes.Object012016.geometry}
        material={materials["Material.004"]}
        position={[25.192, -0.039, 4.176]}
        rotation={[0, 0.862, 0]}
        scale={1.058}
      />
      <mesh
        name="Object012017"
        castShadow
        receiveShadow
        geometry={nodes.Object012017.geometry}
        material={materials["Material.004"]}
        position={[25.192, -0.039, 4.176]}
        rotation={[0, 0.862, 0]}
        scale={1.058}
      />
      <mesh
        name="Object012018"
        castShadow
        receiveShadow
        geometry={nodes.Object012018.geometry}
        material={materials["Material.004"]}
        position={[13.312, -0.039, 1.565]}
        rotation={[0, -1.477, 0]}
        scale={0.994}
      />
      <mesh
        name="Object012019"
        castShadow
        receiveShadow
        geometry={nodes.Object012019.geometry}
        material={materials["Material.004"]}
        position={[8.727, -0.039, -25.919]}
        rotation={[0, -1.477, 0]}
        scale={1.224}
      />
      <mesh
        name="Object012020"
        castShadow
        receiveShadow
        geometry={nodes.Object012020.geometry}
        material={materials["Material.004"]}
        position={[-4.618, -0.039, -13.647]}
        scale={1.274}
      />
      <mesh
        name="Object012021"
        castShadow
        receiveShadow
        geometry={nodes.Object012021.geometry}
        material={materials["Material.004"]}
        position={[10.153, -0.039, -6.756]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012022"
        castShadow
        receiveShadow
        geometry={nodes.Object012022.geometry}
        material={materials["Material.004"]}
        position={[10.24, -0.039, 9.685]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012023"
        castShadow
        receiveShadow
        geometry={nodes.Object012023.geometry}
        material={materials["Material.004"]}
        position={[-2.019, -0.039, 18.123]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012024"
        castShadow
        receiveShadow
        geometry={nodes.Object012024.geometry}
        material={materials["Material.004"]}
        position={[-11.642, -0.039, -1.188]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012026"
        castShadow
        receiveShadow
        geometry={nodes.Object012026.geometry}
        material={materials["Material.004"]}
        position={[10.153, -0.039, -1.218]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012027"
        castShadow
        receiveShadow
        geometry={nodes.Object012027.geometry}
        material={materials["Material.004"]}
        position={[10.217, -0.039, 7.079]}
        rotation={[0, 0.093, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012028"
        castShadow
        receiveShadow
        geometry={nodes.Object012028.geometry}
        material={materials["Material.004"]}
        position={[-6.782, -0.039, -13.534]}
        rotation={[Math.PI, -1.125, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object012029"
        castShadow
        receiveShadow
        geometry={nodes.Object012029.geometry}
        material={materials["Material.004"]}
        position={[-11.648, -0.039, -3.996]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object012030"
        castShadow
        receiveShadow
        geometry={nodes.Object012030.geometry}
        material={materials["Material.004"]}
        position={[10.362, -0.039, -9.449]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object012031"
        castShadow
        receiveShadow
        geometry={nodes.Object012031.geometry}
        material={materials["Material.004"]}
        position={[10.514, -0.028, 15.134]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.459}
      />
      <mesh
        name="Object012032"
        castShadow
        receiveShadow
        geometry={nodes.Object012032.geometry}
        material={materials["Material.004"]}
        position={[10.427, -0.039, 20.568]}
        rotation={[Math.PI, -1.314, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object012033"
        castShadow
        receiveShadow
        geometry={nodes.Object012033.geometry}
        material={materials["Material.004"]}
        position={[10.393, -0.039, 26.206]}
        rotation={[0, -0.272, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012034"
        castShadow
        receiveShadow
        geometry={nodes.Object012034.geometry}
        material={materials["Material.004"]}
        position={[-2.266, -0.039, 23.709]}
        rotation={[0, -0.272, 0]}
        scale={1.274}
      />
      <mesh
        name="Object012035"
        castShadow
        receiveShadow
        geometry={nodes.Object012035.geometry}
        material={materials["Material.004"]}
        position={[-2.301, -0.003, 15.466]}
        rotation={[0, -0.272, 0]}
        scale={1.2}
      />
      <mesh
        name="Object012036"
        castShadow
        receiveShadow
        geometry={nodes.Object012036.geometry}
        material={materials["Material.004"]}
        position={[-2.185, -0.003, 29.295]}
        rotation={[0, 0.786, 0]}
        scale={1.2}
      />
      <mesh
        name="Object012037"
        castShadow
        receiveShadow
        geometry={nodes.Object012037.geometry}
        material={materials["Material.004"]}
        position={[10.248, -0.039, 29.177]}
        rotation={[0, 0.615, 0]}
        scale={1.131}
      />
      <mesh
        name="Object013"
        castShadow
        receiveShadow
        geometry={nodes.Object013.geometry}
        material={materials["Material.004"]}
        position={[13.625, -0.039, -19.147]}
        scale={1.451}
      />
      <mesh
        name="Object013001"
        castShadow
        receiveShadow
        geometry={nodes.Object013001.geometry}
        material={materials["Material.004"]}
        position={[11.623, 0.013, 1.484]}
        scale={1.54}
      />
      <mesh
        name="Object013002"
        castShadow
        receiveShadow
        geometry={nodes.Object013002.geometry}
        material={materials["Material.004"]}
        position={[22.865, -0.039, -9.512]}
        scale={1.233}
      />
      <mesh
        name="Object013003"
        castShadow
        receiveShadow
        geometry={nodes.Object013003.geometry}
        material={materials["Material.004"]}
        position={[11.672, -0.039, 2.812]}
        scale={1.168}
      />
      <mesh
        name="Object013004"
        castShadow
        receiveShadow
        geometry={nodes.Object013004.geometry}
        material={materials["Material.004"]}
        position={[23.995, -0.039, 2.902]}
        scale={1.052}
      />
      <mesh
        name="Object013005"
        castShadow
        receiveShadow
        geometry={nodes.Object013005.geometry}
        material={materials["Material.004"]}
        position={[18.957, -0.039, 18.887]}
        scale={1.052}
      />
      <mesh
        name="Object013006"
        castShadow
        receiveShadow
        geometry={nodes.Object013006.geometry}
        material={materials["Material.004"]}
        position={[-8.119, -0.039, 18.705]}
        scale={1.052}
      />
      <mesh
        name="Object013007"
        castShadow
        receiveShadow
        geometry={nodes.Object013007.geometry}
        material={materials["Material.004"]}
        position={[-16.241, 0.004, 12.035]}
        scale={1.596}
      />
      <mesh
        name="Object013008"
        castShadow
        receiveShadow
        geometry={nodes.Object013008.geometry}
        material={materials["Material.004"]}
        position={[-28.997, -0.039, 23.454]}
        scale={0.912}
      />
      <mesh
        name="Object013009"
        castShadow
        receiveShadow
        geometry={nodes.Object013009.geometry}
        material={materials["Material.004"]}
        position={[-19.971, -0.039, 1.75]}
        scale={0.985}
      />
      <mesh
        name="Object013010"
        castShadow
        receiveShadow
        geometry={nodes.Object013010.geometry}
        material={materials["Material.004"]}
        position={[-18.404, -0.039, -2.237]}
        scale={1.045}
      />
      <mesh
        name="Object013011"
        castShadow
        receiveShadow
        geometry={nodes.Object013011.geometry}
        material={materials["Material.004"]}
        position={[-31.15, -0.039, -10.393]}
        scale={1.045}
      />
      <mesh
        name="Object013012"
        castShadow
        receiveShadow
        geometry={nodes.Object013012.geometry}
        material={materials["Material.004"]}
        position={[-16.535, -0.002, -9.008]}
        scale={1.4}
      />
      <mesh
        name="Object013013"
        castShadow
        receiveShadow
        geometry={nodes.Object013013.geometry}
        material={materials["Material.004"]}
        position={[-18.712, -0.075, -24.329]}
        scale={2.049}
      />
      <mesh
        name="Object013014"
        castShadow
        receiveShadow
        geometry={nodes.Object013014.geometry}
        material={materials["Material.004"]}
        position={[-27.135, -0.039, -21.297]}
        scale={1.177}
      />
      <mesh
        name="Object013015"
        castShadow
        receiveShadow
        geometry={nodes.Object013015.geometry}
        material={materials["Material.004"]}
        position={[-7.794, -0.039, -24.226]}
        scale={1.041}
      />
      <mesh
        name="Object013016"
        castShadow
        receiveShadow
        geometry={nodes.Object013016.geometry}
        material={materials["Material.004"]}
        position={[6.351, -0.039, -24.377]}
        scale={0.993}
      />
      <mesh
        name="Object013017"
        castShadow
        receiveShadow
        geometry={nodes.Object013017.geometry}
        material={materials["Material.004"]}
        position={[6.351, -0.039, -24.377]}
        scale={0.993}
      />
      <mesh
        name="Object013018"
        castShadow
        receiveShadow
        geometry={nodes.Object013018.geometry}
        material={materials["Material.004"]}
        position={[3.712, -0.039, -13.518]}
        scale={1.02}
      />
      <mesh
        name="Object013019"
        castShadow
        receiveShadow
        geometry={nodes.Object013019.geometry}
        material={materials["Material.004"]}
        position={[-1.407, -0.039, -12.029]}
        rotation={[0, 0.871, 0]}
        scale={1.015}
      />
      <mesh
        name="Object013020"
        castShadow
        receiveShadow
        geometry={nodes.Object013020.geometry}
        material={materials["Material.004"]}
        position={[10.024, -0.039, -0.558]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object013021"
        castShadow
        receiveShadow
        geometry={nodes.Object013021.geometry}
        material={materials["Material.004"]}
        position={[7.761, -0.004, -5.882]}
        rotation={[0, -0.7, 0]}
        scale={1.417}
      />
      <mesh
        name="Object013022"
        castShadow
        receiveShadow
        geometry={nodes.Object013022.geometry}
        material={materials["Material.004"]}
        position={[10.111, -0.039, 15.883]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object013023"
        castShadow
        receiveShadow
        geometry={nodes.Object013023.geometry}
        material={materials["Material.004"]}
        position={[8.491, -0.039, 10.908]}
        rotation={[0, -0.7, 0]}
        scale={1.098}
      />
      <mesh
        name="Object013024"
        castShadow
        receiveShadow
        geometry={nodes.Object013024.geometry}
        material={materials["Material.004"]}
        position={[-2.148, -0.039, 24.321]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object013025"
        castShadow
        receiveShadow
        geometry={nodes.Object013025.geometry}
        material={materials["Material.004"]}
        position={[-4.063, -0.021, 18.919]}
        rotation={[0, -0.7, 0]}
        scale={1.379}
      />
      <mesh
        name="Object013026"
        castShadow
        receiveShadow
        geometry={nodes.Object013026.geometry}
        material={materials["Material.004"]}
        position={[-11.771, -0.039, 5.009]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object013027"
        castShadow
        receiveShadow
        geometry={nodes.Object013027.geometry}
        material={materials["Material.004"]}
        position={[-13.805, -0.012, -0.573]}
        rotation={[0, -0.7, 0]}
        scale={1.477}
      />
      <mesh
        name="Object013028"
        castShadow
        receiveShadow
        geometry={nodes.Object013028.geometry}
        material={materials["Material.004"]}
        position={[7.384, -0.009, 23.527]}
        scale={1.473}
      />
      <mesh
        name="Object013029"
        castShadow
        receiveShadow
        geometry={nodes.Object013029.geometry}
        material={materials["Material.004"]}
        position={[-14.404, -0.003, -6.661]}
        scale={1.401}
      />
      <mesh
        name="Object013030"
        castShadow
        receiveShadow
        geometry={nodes.Object013030.geometry}
        material={materials["Material.004"]}
        position={[2.021, 0.013, -11.254]}
        rotation={[0, 0.871, 0]}
        scale={1.459}
      />
      <mesh
        name="Object013031"
        castShadow
        receiveShadow
        geometry={nodes.Object013031.geometry}
        material={materials["Material.004"]}
        position={[-4.383, -0.008, -11.013]}
        rotation={[0, 0.871, 0]}
        scale={1.599}
      />
      <mesh
        name="Object013032"
        castShadow
        receiveShadow
        geometry={nodes.Object013032.geometry}
        material={materials["Material.004"]}
        position={[9.857, 0, 1.096]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.563}
      />
      <mesh
        name="Object013037"
        castShadow
        receiveShadow
        geometry={nodes.Object013037.geometry}
        material={materials["Material.004"]}
        position={[-13.462, -0.039, -10.988]}
        rotation={[0, -0.78, 0]}
        scale={1.255}
      />
      <mesh
        name="Object013039"
        castShadow
        receiveShadow
        geometry={nodes.Object013039.geometry}
        material={materials["Material.004"]}
        position={[-14.43, -0.039, 5.062]}
        rotation={[0, 0.311, 0]}
        scale={1.255}
      />
      <mesh
        name="Object013040"
        castShadow
        receiveShadow
        geometry={nodes.Object013040.geometry}
        material={materials["Material.004"]}
        position={[-12.073, 0.094, 11.906]}
        rotation={[0, 1.424, 0]}
        scale={1.154}
      />
      <mesh
        name="Object013041"
        castShadow
        receiveShadow
        geometry={nodes.Object013041.geometry}
        material={materials["Material.004"]}
        position={[-20.267, -0.005, -3.105]}
        scale={1.413}
      />
      <mesh
        name="Object013042"
        castShadow
        receiveShadow
        geometry={nodes.Object013042.geometry}
        material={materials["Material.004"]}
        position={[-20.63, -0.009, 3.118]}
        scale={1.456}
      />
      <mesh
        name="Object013043"
        castShadow
        receiveShadow
        geometry={nodes.Object013043.geometry}
        material={materials["Material.004"]}
        position={[-15.249, -0.039, 13.54]}
        scale={1.041}
      />
      <mesh
        name="Object013044"
        castShadow
        receiveShadow
        geometry={nodes.Object013044.geometry}
        material={materials["Material.004"]}
        position={[-7.335, 0.001, 18.33]}
        scale={1.377}
      />
      <mesh
        name="Object014"
        castShadow
        receiveShadow
        geometry={nodes.Object014.geometry}
        material={materials["Material.004"]}
        position={[10.88, -0.039, -21.066]}
        rotation={[0, -0.102, 0]}
        scale={1.05}
      />
      <mesh
        name="Object014001"
        castShadow
        receiveShadow
        geometry={nodes.Object014001.geometry}
        material={materials["Material.004"]}
        position={[21.288, -0.036, -5.635]}
        rotation={[0, 0.444, 0]}
        scale={1.458}
      />
      <mesh
        name="Object014003"
        castShadow
        receiveShadow
        geometry={nodes.Object014003.geometry}
        material={materials["Material.004"]}
        position={[30.357, -0.039, 7.167]}
        rotation={[-Math.PI, 0.751, -Math.PI]}
        scale={1.05}
      />
      <mesh
        name="Object014004"
        castShadow
        receiveShadow
        geometry={nodes.Object014004.geometry}
        material={materials["Material.004"]}
        position={[27.704, -0.023, 24.363]}
        rotation={[-Math.PI, 0.751, -Math.PI]}
        scale={1.603}
      />
      <mesh
        name="Object014007"
        castShadow
        receiveShadow
        geometry={nodes.Object014007.geometry}
        material={materials["Material.004"]}
        position={[-28.682, -0.077, 19.704]}
        rotation={[0, -1.349, 0]}
        scale={1.589}
      />
      <mesh
        name="Object014008"
        castShadow
        receiveShadow
        geometry={nodes.Object014008.geometry}
        material={materials["Material.004"]}
        position={[-31.063, -0.039, 3.696]}
        rotation={[0, -1.349, 0]}
        scale={0.944}
      />
      <mesh
        name="Object014009"
        castShadow
        receiveShadow
        geometry={nodes.Object014009.geometry}
        material={materials["Material.004"]}
        position={[-24.821, -0.039, -11.088]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.011}
      />
      <mesh
        name="Object014011"
        castShadow
        receiveShadow
        geometry={nodes.Object014011.geometry}
        material={materials["Material.004"]}
        position={[-22.267, -0.039, -24.803]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.062}
      />
      <mesh
        name="Object014012"
        castShadow
        receiveShadow
        geometry={nodes.Object014012.geometry}
        material={materials["Material.004"]}
        position={[-1.215, -0.039, -28.066]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.062}
      />
      <mesh
        name="Object014013"
        castShadow
        receiveShadow
        geometry={nodes.Object014013.geometry}
        material={materials["Material.004"]}
        position={[30.053, -0.072, -25.139]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.549}
      />
      <mesh
        name="Object015"
        castShadow
        receiveShadow
        geometry={nodes.Object015.geometry}
        material={materials["Material.004"]}
        position={[19.344, -0.039, -19.082]}
        scale={1.131}
      />
      <mesh
        name="Object015001"
        castShadow
        receiveShadow
        geometry={nodes.Object015001.geometry}
        material={materials["Material.004"]}
        position={[10.153, -0.039, 4.049]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015002"
        castShadow
        receiveShadow
        geometry={nodes.Object015002.geometry}
        material={materials["Material.004"]}
        position={[28.073, -0.039, -20.199]}
        scale={1.131}
      />
      <mesh
        name="Object015003"
        castShadow
        receiveShadow
        geometry={nodes.Object015003.geometry}
        material={materials["Material.004"]}
        position={[14.33, -0.039, -24.76]}
        scale={1.131}
      />
      <mesh
        name="Object015004"
        castShadow
        receiveShadow
        geometry={nodes.Object015004.geometry}
        material={materials["Material.004"]}
        position={[-2.072, -0.039, -25.485]}
        scale={1.131}
      />
      <mesh
        name="Object015005"
        castShadow
        receiveShadow
        geometry={nodes.Object015005.geometry}
        material={materials["Material.004"]}
        position={[-8.487, -0.039, -22.876]}
        scale={1.131}
      />
      <mesh
        name="Object015006"
        castShadow
        receiveShadow
        geometry={nodes.Object015006.geometry}
        material={materials["Material.004"]}
        position={[-19.506, -0.039, -22.622]}
        scale={1.131}
      />
      <mesh
        name="Object015007"
        castShadow
        receiveShadow
        geometry={nodes.Object015007.geometry}
        material={materials["Material.004"]}
        position={[-25.486, -0.039, -9.465]}
        scale={1.131}
      />
      <mesh
        name="Object015008"
        castShadow
        receiveShadow
        geometry={nodes.Object015008.geometry}
        material={materials["Material.004"]}
        position={[-10.118, -0.039, -8.124]}
        scale={1.131}
      />
      <mesh
        name="Object015009"
        castShadow
        receiveShadow
        geometry={nodes.Object015009.geometry}
        material={materials["Material.004"]}
        position={[-13.811, -0.039, -4.329]}
        rotation={[0, 0.617, 0]}
        scale={1.131}
      />
      <mesh
        name="Object015010"
        castShadow
        receiveShadow
        geometry={nodes.Object015010.geometry}
        material={materials["Material.004"]}
        position={[-27.416, -0.039, 3.227]}
        rotation={[0, 1.284, 0]}
        scale={1.131}
      />
      <mesh
        name="Object015011"
        castShadow
        receiveShadow
        geometry={nodes.Object015011.geometry}
        material={materials["Material.004"]}
        position={[-17.83, -0.039, -1.662]}
        rotation={[0, 1.284, 0]}
      />
      <mesh
        name="Object015012"
        castShadow
        receiveShadow
        geometry={nodes.Object015012.geometry}
        material={materials["Material.004"]}
        position={[-12.934, -0.039, 8.477]}
        rotation={[0, 1.284, 0]}
        scale={1.04}
      />
      <mesh
        name="Object015013"
        castShadow
        receiveShadow
        geometry={nodes.Object015013.geometry}
        material={materials["Material.004"]}
        position={[-24.949, -0.039, 21.089]}
        rotation={[0, 0.862, 0]}
        scale={1.115}
      />
      <mesh
        name="Object015014"
        castShadow
        receiveShadow
        geometry={nodes.Object015014.geometry}
        material={materials["Material.004"]}
        position={[-2.295, -0.039, 13.586]}
        rotation={[0, 0.862, 0]}
        scale={1.115}
      />
      <mesh
        name="Object015015"
        castShadow
        receiveShadow
        geometry={nodes.Object015015.geometry}
        material={materials["Material.004"]}
        position={[24.371, -0.039, 16.275]}
        rotation={[0, 0.862, 0]}
        scale={1.263}
      />
      <mesh
        name="Object015016"
        castShadow
        receiveShadow
        geometry={nodes.Object015016.geometry}
        material={materials["Material.004"]}
        position={[28.145, -0.039, 0.732]}
        rotation={[0, 0.862, 0]}
        scale={1.058}
      />
      <mesh
        name="Object015017"
        castShadow
        receiveShadow
        geometry={nodes.Object015017.geometry}
        material={materials["Material.004"]}
        position={[28.145, -0.039, 0.732]}
        rotation={[0, 0.862, 0]}
        scale={1.058}
      />
      <mesh
        name="Object015018"
        castShadow
        receiveShadow
        geometry={nodes.Object015018.geometry}
        material={materials["Material.004"]}
        position={[13.71, -0.039, 5.808]}
        rotation={[0, -1.477, 0]}
        scale={0.994}
      />
      <mesh
        name="Object015019"
        castShadow
        receiveShadow
        geometry={nodes.Object015019.geometry}
        material={materials["Material.004"]}
        position={[9.217, -0.039, -20.693]}
        rotation={[0, -1.477, 0]}
        scale={1.224}
      />
      <mesh
        name="Object015020"
        castShadow
        receiveShadow
        geometry={nodes.Object015020.geometry}
        material={materials["Material.004"]}
        position={[0.649, -0.039, -13.647]}
        scale={1.274}
      />
      <mesh
        name="Object015021"
        castShadow
        receiveShadow
        geometry={nodes.Object015021.geometry}
        material={materials["Material.004"]}
        position={[10.153, -0.039, -1.489]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015022"
        castShadow
        receiveShadow
        geometry={nodes.Object015022.geometry}
        material={materials["Material.004"]}
        position={[10.24, -0.039, 14.952]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015023"
        castShadow
        receiveShadow
        geometry={nodes.Object015023.geometry}
        material={materials["Material.004"]}
        position={[-2.019, -0.039, 23.39]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015024"
        castShadow
        receiveShadow
        geometry={nodes.Object015024.geometry}
        material={materials["Material.004"]}
        position={[-11.642, -0.039, 4.079]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015026"
        castShadow
        receiveShadow
        geometry={nodes.Object015026.geometry}
        material={materials["Material.004"]}
        position={[15.462, -0.039, 6.591]}
        rotation={[0, 0.093, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015027"
        castShadow
        receiveShadow
        geometry={nodes.Object015027.geometry}
        material={materials["Material.004"]}
        position={[-9.054, -0.039, -8.782]}
        rotation={[Math.PI, -1.125, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object015028"
        castShadow
        receiveShadow
        geometry={nodes.Object015028.geometry}
        material={materials["Material.004"]}
        position={[-16.516, -0.039, -1.986]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object015029"
        castShadow
        receiveShadow
        geometry={nodes.Object015029.geometry}
        material={materials["Material.004"]}
        position={[5.494, -0.039, -7.439]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object015030"
        castShadow
        receiveShadow
        geometry={nodes.Object015030.geometry}
        material={materials["Material.004"]}
        position={[4.938, -0.028, 17.437]}
        rotation={[Math.PI, -0.392, Math.PI]}
        scale={1.459}
      />
      <mesh
        name="Object015031"
        castShadow
        receiveShadow
        geometry={nodes.Object015031.geometry}
        material={materials["Material.004"]}
        position={[9.091, -0.039, 25.662]}
        rotation={[Math.PI, -1.314, Math.PI]}
        scale={1.274}
      />
      <mesh
        name="Object015032"
        castShadow
        receiveShadow
        geometry={nodes.Object015032.geometry}
        material={materials["Material.004"]}
        position={[15.466, -0.039, 27.622]}
        rotation={[0, -0.272, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015033"
        castShadow
        receiveShadow
        geometry={nodes.Object015033.geometry}
        material={materials["Material.004"]}
        position={[2.807, -0.039, 25.125]}
        rotation={[0, -0.272, 0]}
        scale={1.274}
      />
      <mesh
        name="Object015034"
        castShadow
        receiveShadow
        geometry={nodes.Object015034.geometry}
        material={materials["Material.004"]}
        position={[2.479, -0.003, 16.8]}
        rotation={[0, -0.272, 0]}
        scale={1.2}
      />
      <mesh
        name="Object015035"
        castShadow
        receiveShadow
        geometry={nodes.Object015035.geometry}
        material={materials["Material.004"]}
        position={[1.324, -0.003, 25.785]}
        rotation={[0, 0.786, 0]}
        scale={1.2}
      />
      <mesh
        name="Object015036"
        castShadow
        receiveShadow
        geometry={nodes.Object015036.geometry}
        material={materials["Material.004"]}
        position={[14.067, -0.039, 26.482]}
        rotation={[0, 0.615, 0]}
        scale={1.131}
      />
      <mesh
        name="Object016"
        castShadow
        receiveShadow
        geometry={nodes.Object016.geometry}
        material={materials["Material.004"]}
        position={[19.622, -0.039, -19.147]}
        scale={1.451}
      />
      <mesh
        name="Object016001"
        castShadow
        receiveShadow
        geometry={nodes.Object016001.geometry}
        material={materials["Material.004"]}
        position={[17.992, 0.013, 1.484]}
        scale={1.54}
      />
      <mesh
        name="Object016002"
        castShadow
        receiveShadow
        geometry={nodes.Object016002.geometry}
        material={materials["Material.004"]}
        position={[27.962, -0.039, -9.512]}
        scale={1.233}
      />
      <mesh
        name="Object016003"
        castShadow
        receiveShadow
        geometry={nodes.Object016003.geometry}
        material={materials["Material.004"]}
        position={[16.501, -0.039, 2.812]}
        scale={1.168}
      />
      <mesh
        name="Object016004"
        castShadow
        receiveShadow
        geometry={nodes.Object016004.geometry}
        material={materials["Material.004"]}
        position={[28.342, -0.039, 2.902]}
        scale={1.052}
      />
      <mesh
        name="Object016005"
        castShadow
        receiveShadow
        geometry={nodes.Object016005.geometry}
        material={materials["Material.004"]}
        position={[23.304, -0.039, 18.887]}
        scale={1.052}
      />
      <mesh
        name="Object016006"
        castShadow
        receiveShadow
        geometry={nodes.Object016006.geometry}
        material={materials["Material.004"]}
        position={[-3.772, -0.039, 18.705]}
        scale={1.052}
      />
      <mesh
        name="Object016007"
        castShadow
        receiveShadow
        geometry={nodes.Object016007.geometry}
        material={materials["Material.004"]}
        position={[-9.643, 0.004, 12.035]}
        scale={1.596}
      />
      <mesh
        name="Object016008"
        castShadow
        receiveShadow
        geometry={nodes.Object016008.geometry}
        material={materials["Material.004"]}
        position={[-25.228, -0.039, 23.454]}
        scale={0.912}
      />
      <mesh
        name="Object016009"
        castShadow
        receiveShadow
        geometry={nodes.Object016009.geometry}
        material={materials["Material.004"]}
        position={[-15.9, -0.039, 1.75]}
        scale={0.985}
      />
      <mesh
        name="Object016010"
        castShadow
        receiveShadow
        geometry={nodes.Object016010.geometry}
        material={materials["Material.004"]}
        position={[-14.084, -0.039, -2.237]}
        scale={1.045}
      />
      <mesh
        name="Object016011"
        castShadow
        receiveShadow
        geometry={nodes.Object016011.geometry}
        material={materials["Material.004"]}
        position={[-26.83, -0.039, -10.393]}
        scale={1.045}
      />
      <mesh
        name="Object016012"
        castShadow
        receiveShadow
        geometry={nodes.Object016012.geometry}
        material={materials["Material.004"]}
        position={[-10.748, -0.002, -9.008]}
        scale={1.4}
      />
      <mesh
        name="Object016013"
        castShadow
        receiveShadow
        geometry={nodes.Object016013.geometry}
        material={materials["Material.004"]}
        position={[-10.242, -0.075, -24.329]}
        scale={2.049}
      />
      <mesh
        name="Object016014"
        castShadow
        receiveShadow
        geometry={nodes.Object016014.geometry}
        material={materials["Material.004"]}
        position={[-22.267, -0.039, -21.297]}
        scale={1.177}
      />
      <mesh
        name="Object016015"
        castShadow
        receiveShadow
        geometry={nodes.Object016015.geometry}
        material={materials["Material.004"]}
        position={[-3.49, -0.039, -24.226]}
        scale={1.041}
      />
      <mesh
        name="Object016016"
        castShadow
        receiveShadow
        geometry={nodes.Object016016.geometry}
        material={materials["Material.004"]}
        position={[10.456, -0.039, -24.377]}
        scale={0.993}
      />
      <mesh
        name="Object016017"
        castShadow
        receiveShadow
        geometry={nodes.Object016017.geometry}
        material={materials["Material.004"]}
        position={[10.456, -0.039, -24.377]}
        scale={0.993}
      />
      <mesh
        name="Object016018"
        castShadow
        receiveShadow
        geometry={nodes.Object016018.geometry}
        material={materials["Material.004"]}
        position={[7.93, -0.039, -13.518]}
        scale={1.02}
      />
      <mesh
        name="Object016019"
        castShadow
        receiveShadow
        geometry={nodes.Object016019.geometry}
        material={materials["Material.004"]}
        position={[1.295, -0.039, -15.24]}
        rotation={[0, 0.871, 0]}
        scale={1.015}
      />
      <mesh
        name="Object016020"
        castShadow
        receiveShadow
        geometry={nodes.Object016020.geometry}
        material={materials["Material.004"]}
        position={[10.024, -0.039, 3.981]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object016021"
        castShadow
        receiveShadow
        geometry={nodes.Object016021.geometry}
        material={materials["Material.004"]}
        position={[12.243, -0.004, -2.111]}
        rotation={[0, -0.7, 0]}
        scale={1.417}
      />
      <mesh
        name="Object016022"
        castShadow
        receiveShadow
        geometry={nodes.Object016022.geometry}
        material={materials["Material.004"]}
        position={[10.111, -0.039, 20.422]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object016023"
        castShadow
        receiveShadow
        geometry={nodes.Object016023.geometry}
        material={materials["Material.004"]}
        position={[11.964, -0.039, 13.831]}
        rotation={[0, -0.7, 0]}
        scale={1.098}
      />
      <mesh
        name="Object016024"
        castShadow
        receiveShadow
        geometry={nodes.Object016024.geometry}
        material={materials["Material.004"]}
        position={[-2.148, -0.039, 28.86]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object016025"
        castShadow
        receiveShadow
        geometry={nodes.Object016025.geometry}
        material={materials["Material.004"]}
        position={[0.3, -0.021, 22.591]}
        rotation={[0, -0.7, 0]}
        scale={1.379}
      />
      <mesh
        name="Object016026"
        castShadow
        receiveShadow
        geometry={nodes.Object016026.geometry}
        material={materials["Material.004"]}
        position={[-11.771, -0.039, 9.549]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.098}
      />
      <mesh
        name="Object016027"
        castShadow
        receiveShadow
        geometry={nodes.Object016027.geometry}
        material={materials["Material.004"]}
        position={[-9.132, -0.012, 3.359]}
        rotation={[0, -0.7, 0]}
        scale={1.477}
      />
      <mesh
        name="Object016028"
        castShadow
        receiveShadow
        geometry={nodes.Object016028.geometry}
        material={materials["Material.004"]}
        position={[13.475, -0.009, 23.527]}
        scale={1.473}
      />
      <mesh
        name="Object016029"
        castShadow
        receiveShadow
        geometry={nodes.Object016029.geometry}
        material={materials["Material.004"]}
        position={[-8.61, -0.003, -6.661]}
        scale={1.401}
      />
      <mesh
        name="Object016030"
        castShadow
        receiveShadow
        geometry={nodes.Object016030.geometry}
        material={materials["Material.004"]}
        position={[5.906, 0.013, -15.87]}
        rotation={[0, 0.871, 0]}
        scale={1.459}
      />
      <mesh
        name="Object016031"
        castShadow
        receiveShadow
        geometry={nodes.Object016031.geometry}
        material={materials["Material.004"]}
        position={[-0.126, -0.008, -16.072]}
        rotation={[0, 0.871, 0]}
        scale={1.599}
      />
      <mesh
        name="Object016032"
        castShadow
        receiveShadow
        geometry={nodes.Object016032.geometry}
        material={materials["Material.004"]}
        position={[9.857, 0, 7.56]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={1.563}
      />
      <mesh
        name="Object016037"
        castShadow
        receiveShadow
        geometry={nodes.Object016037.geometry}
        material={materials["Material.004"]}
        position={[-9.776, -0.039, -7.339]}
        rotation={[0, -0.78, 0]}
        scale={1.255}
      />
      <mesh
        name="Object016039"
        castShadow
        receiveShadow
        geometry={nodes.Object016039.geometry}
        material={materials["Material.004"]}
        position={[-9.493, -0.039, 3.473]}
        rotation={[0, 0.311, 0]}
        scale={1.255}
      />
      <mesh
        name="Object016040"
        castShadow
        receiveShadow
        geometry={nodes.Object016040.geometry}
        material={materials["Material.004"]}
        position={[-11.377, 0.094, 7.184]}
        rotation={[0, 1.424, 0]}
        scale={1.154}
      />
      <mesh
        name="Object016041"
        castShadow
        receiveShadow
        geometry={nodes.Object016041.geometry}
        material={materials["Material.004"]}
        position={[-14.427, -0.005, -3.105]}
        scale={1.413}
      />
      <mesh
        name="Object016042"
        castShadow
        receiveShadow
        geometry={nodes.Object016042.geometry}
        material={materials["Material.004"]}
        position={[-14.611, -0.009, 3.118]}
        scale={1.456}
      />
      <mesh
        name="Object016043"
        castShadow
        receiveShadow
        geometry={nodes.Object016043.geometry}
        material={materials["Material.004"]}
        position={[-10.944, -0.039, 13.54]}
        scale={1.041}
      />
      <mesh
        name="Object016044"
        castShadow
        receiveShadow
        geometry={nodes.Object016044.geometry}
        material={materials["Material.004"]}
        position={[-1.642, 0.001, 18.33]}
        scale={1.377}
      />
      <mesh
        name="Object017"
        castShadow
        receiveShadow
        geometry={nodes.Object017.geometry}
        material={materials["Material.004"]}
        position={[15.198, -0.039, -20.625]}
        rotation={[0, -0.102, 0]}
        scale={1.05}
      />
      <mesh
        name="Object017001"
        castShadow
        receiveShadow
        geometry={nodes.Object017001.geometry}
        material={materials["Material.004"]}
        position={[26.731, -0.036, -8.226]}
        rotation={[0, 0.444, 0]}
        scale={1.458}
      />
      <mesh
        name="Object017003"
        castShadow
        receiveShadow
        geometry={nodes.Object017003.geometry}
        material={materials["Material.004"]}
        position={[27.183, -0.039, 4.206]}
        rotation={[-Math.PI, 0.751, -Math.PI]}
        scale={1.05}
      />
      <mesh
        name="Object017004"
        castShadow
        receiveShadow
        geometry={nodes.Object017004.geometry}
        material={materials["Material.004"]}
        position={[22.859, -0.023, 19.843]}
        rotation={[-Math.PI, 0.751, -Math.PI]}
        scale={1.603}
      />
      <mesh
        name="Object017007"
        castShadow
        receiveShadow
        geometry={nodes.Object017007.geometry}
        material={materials["Material.004"]}
        position={[-27.234, -0.077, 26.11]}
        rotation={[0, -1.349, 0]}
        scale={1.589}
      />
      <mesh
        name="Object017008"
        castShadow
        receiveShadow
        geometry={nodes.Object017008.geometry}
        material={materials["Material.004"]}
        position={[-30.202, -0.039, 7.503]}
        rotation={[0, -1.349, 0]}
        scale={0.944}
      />
      <mesh
        name="Object017009"
        castShadow
        receiveShadow
        geometry={nodes.Object017009.geometry}
        material={materials["Material.004"]}
        position={[-28.325, -0.039, -8.812]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.011}
      />
      <mesh
        name="Object017011"
        castShadow
        receiveShadow
        geometry={nodes.Object017011.geometry}
        material={materials["Material.004"]}
        position={[-25.95, -0.039, -22.412]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.062}
      />
      <mesh
        name="Object017012"
        castShadow
        receiveShadow
        geometry={nodes.Object017012.geometry}
        material={materials["Material.004"]}
        position={[-4.898, -0.039, -25.674]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.062}
      />
      <mesh
        name="Object017013"
        castShadow
        receiveShadow
        geometry={nodes.Object017013.geometry}
        material={materials["Material.004"]}
        position={[24.684, -0.072, -21.653]}
        rotation={[Math.PI, -0.576, Math.PI]}
        scale={1.549}
      />
      <mesh
        name="Plane001"
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["Material.003"]}
        position={[-1.954, 0.014, 1.933]}
      />
    </group>
  );
}

useGLTF.preload("./meshes/houses/Building_area.glb");
