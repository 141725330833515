import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconFilter = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Filter_alt_fill">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 2C4.44772 2 4 2.44772 4 3V5.00001H20V3C20 2.44772 19.5523 2 19 2H5ZM19.7822 7.00001H4.21776C4.3321 7.22455 4.48907 7.42794 4.68299 7.59762L10.683 12.8476C11.437 13.5074 12.563 13.5074 13.317 12.8476L19.317 7.59762C19.5109 7.42794 19.6679 7.22455 19.7822 7.00001Z"
        fill="currentColor"
      />
      <path
        id="Subtract_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 16.7049L14 10H10V18.7049L14 16.7049Z"
        fill="currentColor"
      />
    </g>
  </svg>
));
