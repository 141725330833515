import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "app/store";
import {
  Model,
  UiScreen,
  setClickedHoverCode,
  setHouses,
  setModel,
} from "./slice";
import { Login } from "./c/login";
import { House } from "./c/house";
import { useProgress } from "@react-three/drei";
import { Filter } from "./c/filter";
import { HouseDetail } from "./c/house-detail";
import { Configurator } from "./c/configurator";
import { useLoader } from "@react-three/fiber";
import { FileLoader } from "three";
import { useEventListener } from "app/event-listener";

const components: Partial<Record<UiScreen, FC>> = {
  login: Login,
  filter: Filter,
  house: House,
  "house-detail": HouseDetail,
  configurator: Configurator,
};

export function Ui() {
  const dispatch = useDispatch();
  const { screen, models, currentModel, houses } = useSelector(s => s.ui);
  const { loaded, total } = useProgress();
  const C = components[screen];

  const file = useLoader(FileLoader, `./data/${currentModel}.json`);
  useEffect(() => {
    if (!models[currentModel] && typeof file === "string") {
      console.log("loaded " + currentModel);
      dispatch(
        setModel({
          entry: currentModel,
          value: JSON.parse(file) as unknown as Model,
        })
      );
    }
  }, [file, models[currentModel]]);

  const housesFile = useLoader(FileLoader, "./data/houses.json");
  useEffect(() => {
    if (!houses.length && typeof housesFile === "string") {
      dispatch(setHouses(JSON.parse(housesFile)));
    }
  }, []);

  useEventListener("mousedown", () => {
    dispatch(setClickedHoverCode());
  });

  if (loaded && loaded === total && C) return <C />;
  return null;
}

useLoader.preload(FileLoader, "./data/houses.json");
useLoader.preload(FileLoader, "./data/крыша.json");
useLoader.preload(FileLoader, "./data/цоколь.json");
useLoader.preload(FileLoader, "./data/фундамент.json");
useLoader.preload(FileLoader, "./data/стены.json");
useLoader.preload(FileLoader, "./data/межэтажное.json");
useLoader.preload(FileLoader, "./data/полы.json");
