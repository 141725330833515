import { IoSettingsSharp } from "react-icons/io5";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useDispatch, useSelector } from "app/store";
import {
  FilterItem,
  selectOption,
  setFilterItems,
  setScreen,
  setTier,
} from "../slice";
import { useState } from "react";
import { CloseButton } from "./close-button";
import { FilterSelector } from "./filter-selector";
import ReactSlider from "react-slider";
import { FilterRange } from "./filter-range";
import { IconHome } from "./icons/home";
import { IconChevronLeft } from "./icons/chevron-left";
import { IconRoof } from "./icons/roof";
import { IconPrice } from "./icons/price";
import { IconEnergy } from "./icons/energy";
import { IconConstruction } from "./icons/construction";
import { IconLifetime } from "./icons/lifetime";
import { IconFilter } from "./icons/filter";
import { NumberCircle } from "./number-circle";
import { IconBase } from "./icons/base";
import { IconGround } from "./icons/ground";
import { IconFloors } from "./icons/floors";
import { IconWalls } from "./icons/walls";
import { IconInterfloor } from "./icons/interfloor";
import { IconArrowLeft } from "./icons/arrow-left";
import { Collapse } from "./collapse";
import { FancyDropdown } from "./fancy-dropdown";
import { HouseLeftBar } from "./house-left-bar";
import { IconList } from "./icons/list";
import { ConfigurationDropdownNumberedItem } from "./configuration-dropdown-numbered-item";
import { Panel } from "./panel";
import { VariantCard } from "./variant-card";
import { FeatureDropdown } from "./feature-dropdown";
import { IconStar } from "./icons/star";
import { NumberCircleItem } from "./number-circle-item";
import { IconThumbsUp } from "./icons/thumbs-up";
import { IconPremium } from "./icons/premium";
import { PanelFooter } from "./panel-footer";
import { FlexDropdown } from "./flex-dropdown";
import { TextItem } from "./text-item";
import c from "classnames";
import { DefaultViewButton } from "./default-view-button";
import { LegendNumbers } from "./legend-numbers";

export const Configurator = () => {
  const dispatch = useDispatch();

  const cm = useSelector(s => s.ui.currentModel);
  const model = useSelector(s => s.ui.models[cm]);
  const cv = useSelector(s => s.ui.currentVariant);
  const ct = useSelector(s => s.ui.currentTier);

  const variants = model.variants;
  const variant = variants[cv];
  const tiers = variant.tiers;
  const tier = tiers[ct];

  return (
    <>
      <LegendNumbers />
      <DefaultViewButton />
      <Panel position="left" title={variant.name}>
        <div className="flex bg-white justify-between">
          {tiers.map((t, i) => (
            <div
              className={c(
                "extraction-candidate font-display tracking-wider text-1rem w-1/3 h-12 flex items-center justify-center uppercase cursor-pointer hover:opacity-75",
                {
                  "bg-gradient text-white": i === ct,
                }
              )}
              key={i}
              onClick={() => dispatch(setTier(i))}
            >
              {t.name}
            </div>
          ))}
        </div>
        <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
          <div className="py-4 px-6 flex flex-col space-y-4">
            {tier.stats.map((s, i) => (
              <div key={i} className="flex flex-col space-y-4">
                <div className="h-8 text-1rem font-display uppercase py-1">
                  {s.name}
                </div>
                <div className="flex w-full items-center justify-between px-4">
                  {tiers.map((t, j) => (
                    <NumberCircle
                      active={ct === j}
                      number={t.stats[i].value}
                      key={j}
                    />
                  ))}
                </div>
              </div>
            ))}
            <div className="h-[0.0625rem] opacity-[0.15] bg-dark w-full"></div>
            <div className="flex flex-col space-y-4">
              <div className=" text-1.5rem font-display uppercase py-1">
                Сумма
              </div>
              <div className="flex w-full items-center justify-between px-4">
                {tiers.map((t, j) => (
                  <NumberCircle
                    active={ct === j}
                    number={t.stats.map(s => s.value).reduce((a, b) => a + b)}
                    max={t.stats.length * 5}
                    key={j}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="h-6 text-1rem font-display uppercase py-1">
                Цена
              </div>
              <div className="flex w-full items-center justify-between space-2 font-display uppercase">
                {tiers.map((t, j) => (
                  <div key={j}>{tiers[j].price}</div>
                ))}
              </div>
            </div>
          </div>
        </OverlayScrollbarsComponent>
      </Panel>
      <Panel title={tier.name}>
        <OverlayScrollbarsComponent className="h-full bg-fancy text-dark">
          <div className="filter-bg-wrapper px-6 min-h-full py-4 flex flex-col gap-4">
            {variant.description ? (
              <div className="text-paragraph text-dark extraction-candidate w-full py-1 flex-col justify-start items-start gap-1 text-[0.875rem] leading-[140%]">
                {variant.description}
              </div>
            ) : (
              <></>
            )}
            <TextItem title="Цена" text={tier.price} />

            {/* <div className="indent w-full h-6 mb-4"></div> */}

            <FlexDropdown Icon={IconList} heading="Состав">
              <div className="bg-white px-2 py-4 mb-4 flex flex-col">
                {tier.properties
                  .filter(p => p.value !== "-")
                  .map(p => (
                    <ConfigurationDropdownNumberedItem
                      number={p.code}
                      info={p.value !== "-"}
                      title={p.name}
                      caption={p.value}
                      key={p.code}
                    />
                  ))}
              </div>
            </FlexDropdown>
          </div>
        </OverlayScrollbarsComponent>
        <PanelFooter
          onBackClick={() => {
            // dispatch(setTier(0));
            dispatch(setScreen("house-detail"));
          }}
          onActionClick={() => {
            dispatch(selectOption({ name: cm, variant: cv, tier: ct }));
            dispatch(setScreen("house-detail"));
          }}
          actionText="Заказать"
          onAddToCartClick={() => {
            dispatch(setScreen("house"));
            dispatch(selectOption({ name: cm, variant: cv, tier: ct }));
          }}
        />
      </Panel>
    </>
  );
};
