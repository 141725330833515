import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconInterfloor = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 9.75H21C21.1381 9.75 21.25 9.86193 21.25 10V14C21.25 14.1381 21.1381 14.25 21 14.25H3C2.86193 14.25 2.75 14.1381 2.75 14V10C2.75 9.86193 2.86193 9.75 3 9.75ZM19 17.75H20C20.1381 17.75 20.25 17.8619 20.25 18V22C20.25 22.1381 20.1381 22.25 20 22.25H19C18.8619 22.25 18.75 22.1381 18.75 22V18C18.75 17.8619 18.8619 17.75 19 17.75ZM3 17.75H4C4.13807 17.75 4.25 17.8619 4.25 18V22C4.25 22.1381 4.13807 22.25 4 22.25H3C2.86193 22.25 2.75 22.1381 2.75 22V18C2.75 17.8619 2.86193 17.75 3 17.75ZM3 1.75H4C4.13807 1.75 4.25 1.86193 4.25 2V6C4.25 6.13807 4.13807 6.25 4 6.25H3C2.86193 6.25 2.75 6.13807 2.75 6V2C2.75 1.86193 2.86193 1.75 3 1.75ZM20 1.75H21C21.1381 1.75 21.25 1.86193 21.25 2V6C21.25 6.13807 21.1381 6.25 21 6.25H20C19.8619 6.25 19.75 6.13807 19.75 6V2C19.75 1.86193 19.8619 1.75 20 1.75Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
