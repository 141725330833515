import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconWalls = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 21.25C1.86193 21.25 1.75 21.1381 1.75 21L1.75 3C1.75 2.86193 1.86193 2.75 2 2.75L6 2.75C6.13807 2.75 6.25 2.86193 6.25 3L6.25 21C6.25 21.1381 6.13807 21.25 6 21.25H2ZM9.75 20C9.75 19.8619 9.86193 19.75 10 19.75H14C14.1381 19.75 14.25 19.8619 14.25 20V21C14.25 21.1381 14.1381 21.25 14 21.25H10C9.86193 21.25 9.75 21.1381 9.75 21V20ZM9.75 3C9.75 2.86193 9.86193 2.75 10 2.75H14C14.1381 2.75 14.25 2.86193 14.25 3V4C14.25 4.13807 14.1381 4.25 14 4.25H10C9.86193 4.25 9.75 4.13807 9.75 4V3ZM18 21.25C17.8619 21.25 17.75 21.1381 17.75 21V3C17.75 2.86193 17.8619 2.75 18 2.75L22 2.75C22.1381 2.75 22.25 2.86193 22.25 3V21C22.25 21.1381 22.1381 21.25 22 21.25H18Z"
      stroke="#242424"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
));
