import { FC, HTMLAttributes, ReactNode } from "react";
import { FlexDropdown } from "./flex-dropdown";
import { InactiveHouseItem } from "./inactive-house-item";
import { useDispatch, useSelector } from "app/store";
import { DbEntry, selectModel, setScreen, setTier, setVariant } from "../slice";

interface Props {
  Icon?: FC<HTMLAttributes<SVGSVGElement>>;
  title?: string;
  children?: ReactNode;
  model?: string;
}

export const SmartHouseItem: FC<Props> = ({ Icon, title, children, model }) => {
  const dispatch = useDispatch();
  const selection = useSelector(s => s.ui.selection);
  if (children) {
    return (
      <FlexDropdown Icon={Icon} heading={title} defaultCollapsed>
        <div className="bg-white p-4 flex flex-col gap-4">{children}</div>
      </FlexDropdown>
    );
  } else {
    return (
      <InactiveHouseItem
        onClick={e => {
          if (model) {
            dispatch(selectModel(model as DbEntry));
            dispatch(setScreen("house-detail"));
            if (selection.options[model] !== undefined) {
              dispatch(setVariant(selection.options[model][0]));
              dispatch(setTier(selection.options[model][1]));
            } else {
              dispatch(setVariant(0));
              dispatch(setTier(0));
            }
          }
        }}
        Icon={Icon}
        title={title}
      />
    );
  }
};
