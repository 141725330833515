import { useRef, useState } from "react";
import {
  SoftShadows,
  RoundedBox,
  PointerLockControls,
  useCursor,
  OrbitControls,
  Resize,
  MeshTransmissionMaterial,
  Billboard,
  Outlines,
  MeshPortalMaterial,
  // Effects,
  Environment,
  MapControls,
  GizmoHelper,
  GizmoViewport,
} from "@react-three/drei";
import { Bobbing } from "./bobbing";
import { Camera } from "./camera";
import { KbMovable } from "./kbmovable";
import { Skybox } from "./skybox";
import { Box } from "./box";
import { Demo } from "./demo";
import { House1 } from "./house1";
import { Euler, ShadowMaterial, Vector3 } from "three";
import { useDispatch, useSelector } from "app/store";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { ModelSelector } from "./model-selector";
import { CenterResizeScale } from "./center-resize-scale";
import { Grass } from "./grass";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { Effects } from "./effects";
import { useEventListener } from "app/event-listener";
import { PI, clamp } from "app/math";
import { CameraControls } from "./camera-controls";
import { House2 } from "./house2";
import { Porch } from "./porch";
import { FilterScene } from "./filter-scene";
import { CameraLogger } from "./camera-logger";
import { toggleUi } from "features/ui/slice";
import { HouseCameraAnimator } from "./house-camera-animator";
import { mobile } from "app/mobile";

export const Scene = () => {
  const screen = useSelector(s => s.ui.screen);
  const dispatch = useDispatch();
  const mainScene = ["house"].includes(screen);
  const strictCamera = mainScene;
  const configurationScene = ["house-detail", "configurator"].includes(screen);
  const filterScene = ["filter"].includes(screen);

  const house = useSelector(s => s.ui.house);

  useEventListener("keydown", e => {
    if (e.code === "Space") {
      dispatch(toggleUi());
    }
  });

  return filterScene ? (
    <FilterScene />
  ) : (
    <group>
      {/* <Effects
        multisamping={8}
        renderIndex={1}
        disableGamma={false}
        disableRenderPass={false}
        disableRender={false}
      ></Effects> */}
      {/*<Skybox />*/}
      {/* <Box /> */}
      {/* <ambientLight intensity={0.5} /> */}
      {/* <pointLight position={[10, 10, 10]} intensity={1.0} /> */}

      {!configurationScene && (
        <>
          <Effects />
          <Environment
            blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
            background
            files={"limpopo_golf_course_4k.hdr"}
          />
          {/* <directionalLight castShadow /> */}

          {/* temporary change */}
          <CameraControls animate />
          <HouseCameraAnimator />
          {/* <MapControls enableDamping={false} /> */}
          {/* <CameraLogger /> */}

          <group position={[0, -1, 0]} scale={0.15} rotation={[0, PI, 0]}>
            {house === 1 ? <House1 /> : <House2 />}
            <Porch />
            {!mobile() ? <Grass position={[-31.7, 0, -32]} /> : <></>}
          </group>

          {/* <Box scale={[10, 12, 20]} castShadow position={[10, 2.5, 5]} /> */}
          <pointLight castShadow intensity={0.5} position={[5, 25, 30]} />
          {/*<Box position={[5, 25, 30]} color="red" />*/}
        </>
      )}
      {/*<OrbitControls
        ref={orbitControls}
        enablePan={false}
        // enableRotate
        enableZoom
        // minPolarAngle={-Math.PI / 2}
        // minPolarAngle={strictCamera ? Math.PI / 2 : -Math.PI / 2}
        // maxPolarAngle={Math.PI / 2}
        // maxDistance={4}
        // minDistance={1.8}
        target={[0.0, configurationScene ? 0.0 : -0.9, -0.0]}
        // screenSpacePanning
        // autoRotate
        // autoRotateSpeed={2}
          />*/}
      {/*<MapControls
        target={[0.0, -0.9, -0.0]}
      />*/}

      {configurationScene && (
        <>
          <CameraControls target={[0, -1.5, 0]} defaultHeight={0.05} />
          <Environment
            background={false} // can be true, false or "only" (which only sets the background) (default: false)
            blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
            files={["alt_bk.png"]}
            preset={"warehouse"}
            scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
            encoding={undefined} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
          />
          {/* <ambientLight intensity={0.75} /> */}
          {/* <pointLight intensity={0.6} position={[5, 5, 5]} /> */}
          <ModelSelector />
        </>
      )}
    </group>
  );
};
