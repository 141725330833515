import { FC, MouseEventHandler } from "react";
import c from "classnames";
import { TextItem } from "./text-item";
import { useSelector } from "app/store";

interface Props {
  imgSrc: string;
  title: string;
  price: string;
  active?: boolean;
  roofType?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const VariantCard: FC<Props> = ({
  imgSrc,
  title,
  price,
  onClick,
  roofType,
  active = false,
}) => {
  const houseType = useSelector(s => s.ui.house);
  if (houseType === 1 && roofType?.toLocaleLowerCase() === "скатная")
    return <></>;
  if (houseType === 2 && roofType?.toLocaleLowerCase() === "плоская")
    return <></>;
  return (
    <div
      onClick={onClick}
      className={c(
        "cursor-pointer hover:opacity-75 flex gap-4 py-6 px-4 justify-start items-start",
        {
          "bg-white": active,
        }
      )}
    >
      <img className="w-24 h-24 object-cover shrink-0" src={imgSrc} />
      <div className="">
        <div className="self-stretch text-dark text-1rem leading-[normal] font-display uppercase tracking-wider mb-4">
          {title}
        </div>
        <TextItem title="Цена" text={price} />
      </div>
    </div>
  );
};
