/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { HoverGroup } from "./hover-group";
import { HoverMesh } from "./hover-mesh";
import { ConditionalRender } from "./conditional-render";

type GLTFResult = GLTF & {
  nodes: {
    BezierCurve055: THREE.Mesh;
    INTER_ER___MEBEL__DLA_OKNA_FLAT_PART: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_TRUB_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_: THREE.Mesh;
    KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_: THREE.Mesh;
    OBJ_COKAL: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE: THREE.Mesh;
    OBOLOCKA___KROVLA_DLA_TRUB_: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001: THREE.Mesh;
    KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001: THREE.Mesh;
    OBJ_ROOF_1: THREE.Mesh;
    MATERIAL_FASAD2_BLACK_BRICK: THREE.Mesh;
    MATERIAL_FASAD2_DECOR_BRICK: THREE.Mesh;
    MATERIAL_FASAD2_YELLOW_SIDING: THREE.Mesh;
    OBJ_FASAD_2: THREE.Mesh;
    MATERIAL_ROOF2_BLACK_METAL: THREE.Mesh;
  };
  materials: {
    ["Material.011"]: THREE.MeshStandardMaterial;
    ["Material.007"]: THREE.MeshStandardMaterial;
    ["Fasad old"]: THREE.MeshStandardMaterial;
    ["Material.008"]: THREE.MeshStandardMaterial;
    Glass: THREE.MeshStandardMaterial;
    H2_Roof_Bitumen: THREE.MeshStandardMaterial;
    H2_Fasad_black_brick: THREE.MeshStandardMaterial;
    H2_Fasad_decor_brick: THREE.MeshStandardMaterial;
    H2_Fasad_yellow_siding: THREE.MeshStandardMaterial;
    H2_Fasad_white_plaster: THREE.MeshStandardMaterial;
    H2_Roof_Black_Metal2: THREE.MeshStandardMaterial;
  };
};

export function House2(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF(
    "./meshes/houses/House2.glb"
  ) as GLTFResult;
  return (
    <group {...props} dispose={null}>
      <HoverGroup name="Scene">
        <mesh
          name="BezierCurve055"
          castShadow
          receiveShadow
          geometry={nodes.BezierCurve055.geometry}
          material={materials["Material.011"]}
          position={[-7.146, -0.842, -2.312]}
          scale={0.982}
        />
        <mesh
          name="INTER_ER___MEBEL__DLA_OKNA_FLAT_PART"
          castShadow
          receiveShadow
          geometry={nodes.INTER_ER___MEBEL__DLA_OKNA_FLAT_PART.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry}
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_DEREVO_OREH_GORIZONTAL_N_J.geometry
          }
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DETALEJ_SVETL_J.geometry}
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_DIKOGO_KAMNA.geometry}
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KOLONN_2.geometry}
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_KROVLI_PODSIV.geometry}
          material={materials["Material.008"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_POLOKONNIKA.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_TRUB_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_TRUB_.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___NESUSIJ_DLA_VERHA_TERRAS_.geometry}
          material={materials["Material.008"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___NESUSIJ_STEKLO_GOLUBOE_FLAT_PART.geometry
          }
          material={materials.Glass}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA.geometry
          }
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DEREVO_OBSIVKA_TEMNAA__KOPIA_
              .geometry
          }
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <HoverMesh
          name="OBJ_COKAL"
          code={5}
          castShadow
          receiveShadow
          geometry={nodes.OBJ_COKAL.geometry}
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_OKNA004.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE"
          castShadow
          receiveShadow
          geometry={nodes.KONSTRUKTIV___OGRAZDAUSIJ_STEKLO_PROZRACNOE.geometry}
          material={materials.Glass}
          position={[-7.146, -0.842, -2.312]}
          scale={0.982}
        />
        <mesh
          name="OBOLOCKA___KROVLA_DLA_TRUB_"
          castShadow
          receiveShadow
          geometry={nodes.OBOLOCKA___KROVLA_DLA_TRUB_.geometry}
          material={materials["Material.007"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_STEN__STUKATURKA_BELAA001
              .geometry
          }
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <mesh
          name="KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001"
          castShadow
          receiveShadow
          geometry={
            nodes.KONSTRUKTIV___OGRAZDAUSIJ_DLA_DIKOGO_KAMNA001.geometry
          }
          material={materials["Fasad old"]}
          position={[-7.146, 0.482, 5.233]}
          scale={0.982}
        />
        <ConditionalRender model="крыша" variant={11} defaultVariant>
          <HoverMesh
            name="OBJ_ROOF_1"
            code={1}
            castShadow
            receiveShadow
            geometry={nodes.OBJ_ROOF_1.geometry}
            material={materials.H2_Roof_Bitumen}
            position={[-7.146, 0.482, 5.233]}
            scale={0.982}
          />
        </ConditionalRender>
        <ConditionalRender model="крыша" variant={12}>
          <HoverMesh
            name="OBJ_ROOF_1"
            code={1}
            castShadow
            receiveShadow
            geometry={nodes.OBJ_ROOF_1.geometry}
            material={materials.H2_Roof_Black_Metal2}
            position={[-7.146, 0.482, 5.233]}
            scale={0.982}
          />
        </ConditionalRender>
        <mesh
          name="MATERIAL_FASAD2_BLACK_BRICK"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD2_BLACK_BRICK.geometry}
          material={materials.H2_Fasad_black_brick}
        />
        <mesh
          name="MATERIAL_FASAD2_DECOR_BRICK"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD2_DECOR_BRICK.geometry}
          material={materials.H2_Fasad_decor_brick}
        />
        <mesh
          name="MATERIAL_FASAD2_YELLOW_SIDING"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_FASAD2_YELLOW_SIDING.geometry}
          material={materials.H2_Fasad_yellow_siding}
        />
        <ConditionalRender model="стены" variant={0} defaultVariant>
          <HoverMesh
            code={3}
            name="OBJ_FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ_FASAD_2.geometry}
            material={materials.H2_Fasad_white_plaster}
            position={[-7.146, 0.482, 5.233]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={1}>
          <HoverMesh
            code={3}
            name="OBJ_FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ_FASAD_2.geometry}
            material={materials.H2_Fasad_decor_brick}
            position={[-7.146, 0.482, 5.233]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={2}>
          <HoverMesh
            code={3}
            name="OBJ_FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ_FASAD_2.geometry}
            material={materials.H2_Fasad_black_brick}
            position={[-7.146, 0.482, 5.233]}
          />
        </ConditionalRender>
        <ConditionalRender model="стены" variant={3}>
          <HoverMesh
            code={3}
            name="OBJ_FASAD_2"
            castShadow
            receiveShadow
            geometry={nodes.OBJ_FASAD_2.geometry}
            material={materials.H2_Fasad_yellow_siding}
            position={[-7.146, 0.482, 5.233]}
          />
        </ConditionalRender>
        <mesh
          name="MATERIAL_ROOF2_BLACK_METAL"
          castShadow
          receiveShadow
          geometry={nodes.MATERIAL_ROOF2_BLACK_METAL.geometry}
          material={materials.H2_Roof_Black_Metal2}
        />
      </HoverGroup>
    </group>
  );
}

useGLTF.preload("./meshes/houses/House2.glb");
