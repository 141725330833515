import { useEventListener } from "app/event-listener";
import { useDispatch, useSelector } from "app/store";
import { FC, useEffect, useRef, useState } from "react";
import { setHoverCode } from "../slice";
import { useLoader } from "@react-three/fiber";
import { FileLoader } from "three";

export interface Bubble {
  x: number;
  y: number;
  value: number;
}

export const LegendNumbers: FC = () => {
  // const [state, setState] = useState<Bubble[]>([]);
  const model = useSelector(s => s.ui.currentModel);
  const variant = useSelector(s => s.ui.currentVariant);
  const tier = useSelector(s => s.ui.currentTier);
  /* useEventListener("mousedown", e => {
    const { pageX, pageY, button } = e;
    if (button === 0 && pageX > 23 * 16) {
      setState(prev =>
        prev.concat({
          x:
            (pageX - (window.innerWidth - window.innerHeight) / 2) /
            window.innerHeight,
          y: pageY / window.innerHeight,
          value: prev.length + 1,
        })
      );
    } else if (button === 2) {
      setState(prev => prev.slice(0, prev.length - 1));
    }
  });
  useEventListener("contextmenu", e => {
    if (!e.ctrlKey) {
      e.preventDefault();
    }
  });
  const windowRef = useRef(window.document);
  useEventListener(
    "keydown",
    e => {
      if (e.key === "d" && e.ctrlKey) {
        e.preventDefault();
        // download as json
        const a = document.createElement("a");
        a.href = URL.createObjectURL(
          new Blob([JSON.stringify(state)], {
            type: "application/json",
          })
        );
        a.download = `${model}${variant}${tier}.json`;
        a.click();
      }
    },
    windowRef
  );
  const [counter, setCounter] = useState(0);
  useEventListener("resize", e => {
    setCounter(prev => prev + 1);
  });*/
  const dispatch = useDispatch();

  // fetch data from the server
  const file = useLoader(
    FileLoader,
    `/data/numbers/${model}${variant}${tier}.json`
  );
  const state = typeof file === "string" ? (JSON.parse(file) as Bubble[]) : [];
  /*useEffect(() => {
    setState([]);
    fetch(`/data/numbers/${model}${variant}${tier}.json`)
      .then(res => res.json())
      .then(setState);
  }, [model, variant, tier]);*/

  const opacity = useSelector(s => s.ui.defaultView) ? 1 : 0;

  return (
    <>
      {state.map((b, i) => (
        <div
          key={i}
          onPointerOver={() => {
            dispatch(setHoverCode(b.value));
          }}
          className="fixed w-5 h-5 bg-white border border-dark text-dark rounded-full transform translate-x-[-50%] translate-y-[-50%] flex items-center justify-center text-xs select-none transition-opacity"
          style={{
            opacity,
            top: b.y * window.innerHeight,
            left:
              b.x * window.innerHeight +
              (window.innerWidth - window.innerHeight) / 2,
          }}
        >
          {b.value}
        </div>
      ))}
    </>
  );
};
