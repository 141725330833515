import { HTMLAttributes, forwardRef } from "react";
import c from "classnames";

export const IconConstruction = forwardRef<
  SVGSVGElement,
  HTMLAttributes<SVGSVGElement>
>(({ children, ...props }, ref) => (
  <svg
    ref={ref}
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icn construction time">
      <path
        d="M12 8V13"
        stroke="#242424"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25"
        stroke="#242424"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 2H15"
        stroke="#242424"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8999 18.5V17.34C14.8999 15.91 15.9199 15.32 17.1599 16.04L18.1599 16.62L19.1599 17.2C20.3999 17.92 20.3999 19.09 19.1599 19.81L18.1599 20.39L17.1599 20.97C15.9199 21.69 14.8999 21.1 14.8999 19.67V18.5Z"
        stroke="#242424"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
));
