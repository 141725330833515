import { FC, useState } from "react";
import { HouseScreenControlItem } from "./house-screen-control-item";
import { mobile } from "app/mobile";
import { IconChevronLeft } from "./icons/chevron-left";
import classNames from "classnames";

export const HouseLeftBar: FC = () => {
  const [closed, setClosed] = useState(mobile());
  return (<>
    <div className={classNames("house-left-bar fixed top-0 left-0 h-screen flex flex-col gap-8 px-12 justify-center items-start transition-all", {
"left-0": !closed,
"left-[-100vw]": closed
    })}>

      <HouseScreenControlItem name="Крыша" model="крыша" code={1} />
      <HouseScreenControlItem
        name="Межэтажное перекрытие"
        model="межэтажное"
        code={2}
      />
      <HouseScreenControlItem name="Стены" model="стены" code={3} />
      <HouseScreenControlItem name="Полы по грунту" model="полы" code={4} />
      <HouseScreenControlItem name="Цоколь" model="цоколь" code={5} />
      <HouseScreenControlItem name="Фундамент" model="фундамент" code={6} />
    </div>
<div
        className={classNames("filter-button group absolute top-0 left-0 w-14 h-14 flex justify-center items-center bg-dark text-white cursor-pointer", {"hidden": !mobile()})}
        onClick={() => setClosed(prev => !prev)}
      >
        <IconChevronLeft className={classNames("w-6 h-6 group-hover:opacity-75 relative top-0.5 right-0.5 transform transition-transform", {"rotate-180": closed})} />
      </div>

  </>
  );
};
